import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AdminNavbarPage from "./../headers/Admin_header";
import useAuth from "../../../hooks/usesAuth";
import AuthAPI from "../../../Services/auth/authAPI";
import AdminSidebar from "./../sidebars/AdminSidebar";
// import Footer from "./footers/Footer";

export const AdminLayout = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <AdminNavbarPage/>
            <AdminSidebar/>
            <main id="main" className="main">
                <Outlet/>
            </main>
            {/*<Footer/>*/}
        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default AdminLayout;