import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import  UserAPI from "../../../apis/adminApi/userAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const UpdateUserPassword = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    let {userId} = useParams();
    const [showLoader, setShowLoader] = useState(false);

    const [utilisateur, setUser] = useState({
        password : "", password_confirmation : "", name: "", id : userId
    });


    const [errors, setErrors] = useState({
        password : "", password_confirmation : "",
    });

    //Recuperation d'une facture
    const fetchUser = async id => {
        try{
            const { name, email, phone, city, role, actived } = await UserAPI.find(id);
            setUser({ name, email, phone, city, role, actived });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'utilisateur démandé !");
            navigate("/admin");
        }
    };


    useEffect(() => {
        fetchUser(userId);
    }, [userId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUser({...utilisateur, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await UserAPI.updatePassword(userId, utilisateur);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Utilisateur modifié avec succès");
            navigate("/admin/vendeurs");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification de l'utilisateur !");
            }else if (error) {
                setTimeout(() => setShowLoader(false), 1000);
                toast.error(error);
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier le mot de passe de
                                            <b className="text-primary"> {utilisateur.name}</b>
                                        </h5>

                                        <Field
                                            name="password"
                                            type="password"
                                            error={errors.password}
                                            value={utilisateur.password}
                                            onChange={handleChange}
                                            label="Mot de passe"
                                        />

                                        <Field
                                            name="password_confirmation"
                                            type="password"
                                            error={errors.password_confirmation}
                                            value={utilisateur.password_confirmation}
                                            onChange={handleChange}
                                            label="Confirmez le mot de passe"
                                        />

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to={`/admin/vendeurs`} className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form> }
                </div>
            </div>
        </div>
    );
};

export default UpdateUserPassword;
