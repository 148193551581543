import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../../Services/userProvider";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import BusesHoursAPI from "../../../apis/adminApi/busHourAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddBusHour = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);

    const [bus_hour, setbus_hour] = useState({
        bus_hour : "",
    });

    const [errors, setErrors] = useState({
        bus_hour : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setbus_hour({...bus_hour, [name] : value})
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BusesHoursAPI.create(bus_hour);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("L'heure de Bus ajouté avec succès");
            navigate("/admin/busesHours");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification de l'heure de  bus !");
            }
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une heure de bus</h5>
                                <Field
                                    name="bus_hour"
                                    error={errors.bus_hour}
                                    value={bus_hour.bus_hour}
                                    onChange={handleChange}
                                    label="Heure"
                                    placeholder="Heure"
                                />
                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/busesHours" className="btn btn-info mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBusHour;
