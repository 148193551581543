import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import {useForm} from "react-hook-form";
import BusesAPI from "../../../apis/adminApi/busAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddTrajet = () => {
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);

    const [bus, setBus] = useState({
        bus_code : "", bus_numberplate : "", bus_seat_number : ""
    });

    const [errors, setErrors] = useState({
        bus_code : "", bus_numberplate : "", bus_seat_number : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBus({...bus, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BusesAPI.create(bus);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Bus ajouté avec succès");
            navigate("/admin/buses");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un bus</h5>
                                <Field
                                name="bus_code"
                                error={errors.bus_code}
                                value={bus.bus_code}
                                onChange={handleChange}
                                label="Code du bus"
                                placeholder="Code du bus"
                                />

                                <Field
                                    name="bus_numberplate"
                                    error={errors.bus_numberplate}
                                    value={bus.bus_numberplate}
                                    onChange={handleChange}
                                    label="Numero de plaque"
                                    placeholder="Plaque"
                                />

                                <Field
                                    name="bus_seat_number"
                                    error={errors.bus_seat_number}
                                    value={bus.bus_seat_number}
                                    onChange={handleChange}
                                    label="Nombre de sièges"
                                    placeholder="Siege"
                                />
                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/buses" className="btn btn-info mx-2">Revenir aux bus</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTrajet;
