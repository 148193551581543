import React, { useState, useEffect, useRef } from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Countdown from 'react-countdown'
import {faCheck, faTimes, faInfoCircle,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";


const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const ResetPasswordPage = () => {
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const userRef = useRef();
    const [validationCode, setValidationCode] = useState("");
    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [password_confirm, setPassword_confirm] = useState("");
    const [validPassword_confirm, setValidPassword_confirm] = useState(false);
    const [password_confirmFocus, setPassword_confirmFocus] = useState(false);
    const [user, setUser] = useState("");
    let {username} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        const match = password === password_confirm;
        setValidPassword_confirm(match);
    }, [password, password_confirm]);

    useEffect(() => {

    }, [password, password_confirm]);



    function resendCode() {
        navigate('forgot-password');
    }


    function ressetPassword() {
        return new Promise(resolve => {
            setTimeout(() => {
                const requestBody = {
                    validationCode: validationCode,
                    username: username,
                    password: password
                };

                fetch("/api/v1/resetPassword",{
                    headers : {
                        "Content-Type" : "application/json"
                    },
                    method : "POST",
                    body : JSON.stringify(requestBody)
                }).then((res) => res.json()).then((data) => {
                    if (data.status === 200) {
                        toast.success(data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate("/")
                    } else if (data.status === 400){
                        toast.error(data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((err) => {
                    console.log(err.response);
                });
                resolve();
            }, 3000);
        });
    }


    const Completionist = () => <span className="text-danger">Code invalide !</span>;

// Renderer callback with condition
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
        {minutes}:{seconds}
      </span>
            );
        }
    };

    const getLocalStorageValue = (s) => localStorage.getItem(s);

    const [data, setData] = useState(
        { date: Date.now(), delay: 60000 * 15 } //60 seconds
    );
    const wantedDelay = 60000 * 10; //60 s


    useEffect(() => {
        const savedDate = getLocalStorageValue("end_date");

        if (savedDate != null && !isNaN(savedDate)) {
            const currentTime = Date.now();
            const delta = parseInt(savedDate, 10) - currentTime;

            //Do you reach the end?
            if (delta > wantedDelay) {
                //Yes we clear uour saved end date
                if (localStorage.getItem("end_date").length > 0)
                    localStorage.removeItem("end_date");
            } else {
                //No update the end date
                setData({ date: currentTime, delay: delta });
            }
        }
    }, []);


    return (
        <main>
            <div className="container">

                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <Link to="/" className="logo d-flex align-items-center w-auto">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt=""/>
                                        <span className="d-none d-lg-block">KKS EXPRESS</span>
                                    </Link>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Activez votre compte</h5>
                                            <p className="text-center small">
                                                <Countdown
                                                    date={data.date + data.delay}
                                                    renderer={renderer}
                                                    onStart={(delta) => {
                                                        //Save the end date
                                                        if (localStorage.getItem("end_date") == null)
                                                            localStorage.setItem(
                                                                "end_date",
                                                                JSON.stringify(data.date + data.delay)
                                                            );
                                                    }}
                                                    onComplete={() => {
                                                        if (localStorage.getItem("end_date") != null)
                                                            localStorage.removeItem("end_date");
                                                    }}
                                                />
                                            </p>
                                        </div>

                                        <form className="row g-3" onSubmit={handleSubmit(ressetPassword)}>

                                            <div className="col-12">
                                                <label htmlFor="username" className="form-label">Code</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-code"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        required
                                                        id="validationCode"
                                                        value={validationCode}
                                                        onChange={(e) => setValidationCode(e.target.value)}
                                                        className="form-control"
                                                        placeholder="code"
                                                    />
                                                    <div className="invalid-feedback">Please enter your username.
                                                    </div>
                                                </div>

                                                <br/>
                                                <div className="col-12">
                                                    <label htmlFor="password">
                                                        Mot de passe{" "} <FontAwesomeIcon icon={faCheck} className={validPassword ? "text-success p-1" : "d-none"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "d-none" : "text-danger p-1"}/>
                                                    </label>
                                                    <div className="input-group">
                                                     <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-lock"></i>
                                                    </span>
                                                        <input
                                                            className="form-control"
                                                            id="password"
                                                            type="password"
                                                            name="password"
                                                            value={password}
                                                            placeholder="Mot de passe"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            aria-invalid={validPassword ? "false" : "true"}
                                                            aria-describedby="pwdidnote"
                                                            onFocus={() => setPasswordFocus(true)}
                                                            onBlur={() => setPasswordFocus(false)}
                                                        />
                                                    </div>
                                                    <div id="pwdidnote" className={passwordFocus && password && !validPassword ? "mt-2" : "d-none"}>
                                                        <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                            <i className="bi bi-info-circle"></i> 8 à 24 charactères.
                                                            <br /> Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial
                                                            <br />Caractères spéciaux autorisés :{" "}
                                                            <span aria-label="exclamation mark">!</span>{" "}
                                                            <span aria-label="at symbol">@</span>{" "}
                                                            <span aria-label="hashtag">#</span>{" "}
                                                            <span aria-label="dollar sign">$</span>{" "}
                                                            <span aria-label="percent">%</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="col-12">
                                                    <label htmlFor="password_confirm">
                                                        Confirmer le mot de passe{" "}<FontAwesomeIcon icon={faCheck} className={validPassword_confirm && password_confirm
                                                        ? "text-success p-1" : "d-none"}/>
                                                        <FontAwesomeIcon icon={faTimes} className={validPassword_confirm || !password_confirm ? "d-none" : "text-danger p-1"}/>
                                                    </label>
                                                    <div className="input-group">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-lock"></i>
                                                    </span>
                                                        <input
                                                            className="form-control"
                                                            id="password_confirm"
                                                            type="password"
                                                            value={password_confirm}
                                                            name="password_confirm"
                                                            placeholder="Confirmer le mot de passe"
                                                            onChange={(e) => setPassword_confirm(e.target.value)}
                                                            aria-invalid={validPassword_confirm ? "false" : "true"}
                                                            aria-describedby="pwdcidnote"
                                                            onFocus={() => setPassword_confirmFocus(true)}
                                                            onBlur={() => setPassword_confirmFocus(false)}
                                                        />
                                                    </div>
                                                    <div id="pwdcidnote" className={password_confirmFocus && password_confirm && !validPassword_confirm
                                                        ? "mt-2" : "d-none"}>
                                                        <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                            <i className="bi bi-info-circle"></i>
                                                            Les mots de passe ne sont pas identiques
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <Link to="/forgot-password" exact>Renvoyer le code</Link>
                                            </div>
                                            <div className="col-12">
                                                <button disabled={isSubmitting} className="btn btn-primary w-100">
                                                    {isSubmitting &&
                                                    <><span className="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>...</>
                                                    } Réinitialiser
                                                </button>
                                            </div>
                                            {/*<div className="col-12">*/}
                                                {/*<p className="small mb-0">Vous n'avez pas de compte? <Link*/}
                                                    {/*to="/register">Créer un compte</Link></p>*/}
                                            {/*</div>*/}
                                        </form>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
};

export default ResetPasswordPage;