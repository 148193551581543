import axios from "axios";
import {FRET_API} from "../../config";
import Cache from "../cache";

async function findAllFrets() {
    return axios
        .get(FRET_API + "/adminAllFretsSales")
        .then(response => {
            const frets = response.data.frets;
            Cache.set("AdminAllFrets", frets);
            return frets;
        });
}

async function findAllWeeklyFrets() {
    return axios
        .get(FRET_API + "/adminAllWeeklyFretsSales")
        .then(response => {
            const frets = response.data.frets;
            Cache.set("AdminAllFrets", frets);
            return frets;
        });
}

async function findAllFretsByCity(city) {
    return axios
        .get(FRET_API + "/adminAllFretsSalesByCity/"+ city)
        .then(response => {
            const frets = response.data.frets;
            Cache.set("AdminAllFrets", frets);
            return frets;
        });
}

async function findAllWeeklyFretsByCity(city) {
    return axios
        .get(FRET_API + "/adminAllWeeklyFretsSalesByCity/"+ city)
        .then(response => {
            const frets = response.data.frets;
            Cache.set("AdminAllFrets", frets);
            return frets;
        });
}



async function findWeeklyFretsByCity(city) {
    return axios
        .get(FRET_API + "/adminWeeklyFretsByCity/"+city)
        .then(response => {
            const dailyFrets = response.data.frets;
            Cache.set("AdminDailyFrets", dailyFrets);
            return dailyFrets;
        });
}


async function findDailyFretsByCity(city) {
    return axios
        .get(FRET_API + "/adminDailyFretsByCity/"+city)
        .then(response => {
            const dailyFrets = response.data.frets;
            Cache.set("AdminDailyFrets", dailyFrets);
            return dailyFrets;
        });
}

async function findWeeklyFrets() {
    return axios
        .get(FRET_API + "/adminWeeklyFrets")
        .then(response => {
            const dailyFrets = response.data.frets;
            Cache.set("AdminDailyFrets", dailyFrets);
            return dailyFrets;
        });
}

async function findDailyFrets() {
    return axios
        .get(FRET_API + "/adminDailyFrets")
        .then(response => {
            const dailyFrets = response.data.frets;
            Cache.set("AdminDailyFrets", dailyFrets);
            return dailyFrets;
        });
}

async function findAdminSalesDailyFretsByCity(city) {
    return axios
        .get(FRET_API + "/findAdminSalesDailyFretsByCity/"+city)
        .then(response => {
            const dailyFrets = response.data.frets;
            Cache.set("AdminDailyFrets", dailyFrets);
            return dailyFrets;
        });
}


async function findAdminSalesDailyFrets() {
    return axios
        .get(FRET_API + "/findAdminSalesDailyFrets")
        .then(response => {
            const dailyFrets = response.data.frets;
            Cache.set("AdminDailyFrets", dailyFrets);
            return dailyFrets;
        });
}

async function findDailyTotal() {
    return axios
        .get(FRET_API + "/dailyFrets")
        .then(response => {
            const total = response.data.total;
            Cache.set("AdminDailyTotal", total);
            return total;
        });
}


async function find(id){
    return axios.get(FRET_API + "/" + id)
        .then(response => {
            const fret = response.data.fret;
            Cache.set("AdminAllFrets." + id, fret);
            return fret;
        });
}

async function findOneDaily(id){
    return axios.get(FRET_API + "/" + id)
        .then(response => {
            const fret = response.data.fret;
            Cache.set("AdminDailyFrets." + id, fret);
            return fret;
        });
}



export default {
    findDailyFrets,
    findWeeklyFrets,
    findDailyFretsByCity,
    findWeeklyFretsByCity,
    findAllFrets,
    findAllWeeklyFrets,
    findAllFretsByCity,
    findAllWeeklyFretsByCity,
    find,
    findOneDaily,
    findAdminSalesDailyFrets,
    findAdminSalesDailyFretsByCity,
    findDailyTotal,
};