import React, { useState } from 'react';
import {useLocalState} from "../../../utils/useLocalState";
import {jwtDecode} from "jwt-decode";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const ForgotPasswordPage = () => {
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);


    function forgotPassword() {
        return new Promise(resolve => {
            setErrorMsg("");
            setTimeout(() => {
                const requestBody = {
                    username: username,
                };

                fetch("/api/v1/forgotPassword",{
                    headers : {
                        "Content-Type" : "application/json"
                    },
                    method : "POST",
                    body : JSON.stringify(requestBody)
                }).then((res) => res.json()).then((data) => {
                    if (data.status === 200) {
                        toast.success(data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        //setErrorMsg(data.message);
                        navigate("/reset-password/"+data.username);
                    } else if (data.status === 400){
                        toast.error(data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        //setErrorMsg(data.message);
                    }
                }).catch((err) => {
                    console.log(err.response);
                });
                resolve();
            }, 3000);
        });

    }

    return (
        <main>
            <div className="container">

                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <Link to="/" className="logo d-flex align-items-center w-auto">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt=""/>
                                        <span className="d-none d-lg-block">KKS EXPRESS</span>
                                    </Link>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Mot de passe oublié?</h5>
                                            <p className="text-center small">Entrez votre adresse mail, vous allez récevoir un code pour reinitialiser votre mot de passe
                                            </p>
                                        </div>

                                        <form className="row g-3 needs-validation" onSubmit={handleSubmit(forgotPassword)}>

                                            <div className="col-12">
                                                <label htmlFor="username" className="form-label">E-mail</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-person"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="username"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                        className="form-control"
                                                        placeholder="nom@test.com"
                                                    />
                                                    <div className="invalid-feedback">Please enter your username.
                                                    </div>
                                                </div>
                                            </div>

                                            {errorMsg ? (
                                                <div className="col-12 justify-content-center mb-4">
                                                    <div className="" style={{ color: "red" }}>
                                                        <i className="bi bi-exclamation-triangle"></i> {errorMsg}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="col-12">
                                                <button disabled={isSubmitting} className="btn btn-primary w-100">
                                                    {isSubmitting &&
                                                    <><span className="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>...</>
                                                    } Reinitialiser
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
};

export default ForgotPasswordPage;