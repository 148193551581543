import React, { useEffect, useState } from 'react';
import ajax from '../../../Services/fetchService';
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../../Services/userProvider";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const AddSetting = () => {
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const user = useUser();
    const navigate = useNavigate();
    const [bus_code, setFrom] = useState("");
    const [bus_numberplate, setTo] = useState("");
    const [bus_seat_number, setPrice] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const [showLoader, setShowLoader] = useState(false);

    const requestBody = {
        bus_code : bus_code,
        bus_numberplate : bus_numberplate,
        bus_seat_number : bus_seat_number
    };

    function save(){
        return new Promise(resolve => {
            setErrorMsg("");
            setShowLoader(true);
            if (requestBody.bus_code !== "" || requestBody.bus_numberplate !== "" || requestBody.bus_seat_number !== ""){
                ajax(`/api/v1/admin/buses/store`, "POST", user.jwt, requestBody)
                    .then((response) => {
                        if (response.status === 422) {
                            const errorItems = Object.keys(response.errors).map( (key, i) => {
                                const message = response.errors[key][0];
                                //setErrorMsg(key+" : "+message);
                                setErrorMsg(message);
                            });
                        }else if(response.status === 400){
                            setErrorMsg(response.error);
                        }else{
                            navigate("/admin/buses");
                        }

                    });
            } else{
                setErrorMsg("Aucun champ ne peut etre vide");
            }
            setTimeout(() => setShowLoader(false), 1000);
        });
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit(save)}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un bus</h5>
                                <div className="mt-1">
                                    <label htmlFor="bus_code">Code (Numero)</label>
                                    <input
                                        placeholder="#000"
                                        className="form-control"
                                        id="bus_code"
                                        type="text"
                                        onChange={(e) => setFrom(e.target.value)}
                                        value={bus_code}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="bus_numberplate">Plaque</label>
                                    <input
                                        placeholder="###"
                                        className="form-control"
                                        id="bus_numberplate"
                                        type="text"
                                        onChange={(e) => setTo(e.target.value)}
                                        value={bus_numberplate}
                                    />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="bus_seat_number">Nombre de siège</label>
                                    <input
                                        placeholder="00"
                                        className="form-control"
                                        id="bus_seat_number"
                                        type="number"
                                        onChange={(e) => setPrice(e.target.value)}
                                        value={bus_seat_number}
                                    />
                                </div>
                                {errorMsg ? (
                                    <div className="col-12 justify-content-center mt-3 mb-4">
                                        <div className="" style={{ color: "red" }}>
                                            <i className="bi bi-exclamation-triangle"></i> {errorMsg}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/trajets" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSetting;
