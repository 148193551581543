import React, { useEffect, useState, useRef } from 'react';
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import PrintComponent from "./printer";
import {toast} from "react-toastify";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import Pagination from "../../assets/Pagination";
import Field from "../../forms/Field";
import Select from "../../forms/Select";
import MyButton from "../../assets/MyButton";
import CheckBoxField from "../../forms/CheckBoxField";
import Moment from "moment/moment";
import TableLoader from "../../loaders/TableLoader";

 const NewVenteTransite = () => {
     const componentRef = useRef();
     const { formState } = useForm();
     const { isSubmitting } = formState;
     const [showLoader, setShowLoader] = useState(false);

     const [trajs, setTrajets] = useState();
     const [total, setTotal] = useState();
     const [dailyTickets, setDailyTickets] = useState([]);
     const [buses, setBuses] = useState([]);
     const [loading, setLoading] = useState(true);
     const [setting, setSetting] = useState([]);

     const [checked, setChecked] = useState(false);

     const fetchTransitsAssignments = async () => {
         try{
             const data =  await VendeurAPI.getTransitAssignmentsBuses();
             setBuses(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des affectations transites du jours !");
         }
     };

     const getTrajets = async () => {
         try{
             const data =  await VendeurAPI.getTrajets();
             setTrajets(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des trajets !");
         }
     };

     const fetchSetting = async () => {
         try{
             const data =  await VendeurAPI.findSetting();
             setSetting(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des paramettres !");
         }
     };

     const dailyTransitsSales = async () => {
         try{
             const data =  await VendeurAPI.dailyTransitsSales();
             setDailyTickets(data.buses);
             setTotal(data.total);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des ventes du jour !");
         }
     };

     useEffect(() => {
         fetchTransitsAssignments();
         getTrajets();
         dailyTransitsSales();
         fetchSetting()
     },[]);


     const [ticket, setTicket] = useState({
         customer : "", phone:"", destination : "", assignment_id : "", bonus : 0
     });

     const [errors, setErrors] = useState({
         customer : "",phone:"", destination : "", assignment_id : ""
     });


     const handleChange = ({currentTarget}) => {
         const {name, value} = currentTarget;
         setTicket({...ticket, [name] : value});
     };

     useEffect(() => {
         //console.log(ticket);
     },[ticket]);

     const [currentPage, setCurrentPage] = useState(1);
     const [search, setSearch] = useState("");

     //Gestion du changement de page
     const handleChangeCurrentPage = page => {
         setCurrentPage(page);
     };

     //Gestion de la recherche
     const handleSearch = ({currentTarget}) => {
         setSearch(currentTarget.value);
         setCurrentPage(1);
     };

     const itemsPerPage = 100;

     //Filtrage des buses en fonction de la recherche

     const filteredTickets = dailyTickets.filter(

         b =>
             b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
             b.to.toLowerCase().includes(search.toLowerCase()) ||
             //b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
             b.from.toLowerCase().includes(search.toLowerCase())
     );


     //Pagination des données
     const paginatedTickets = Pagination.getData(
         filteredTickets,
         currentPage,
         itemsPerPage
     );


     const handleChex = () => {
         setChecked(!checked);
         if (!checked === true) {
             setTicket({...ticket, bonus : 1});
         }else{
             setTicket({...ticket, bonus : 0});
         }

     };


     const handleSubmit = async (event) => {
         event.preventDefault();

         try{

             setShowLoader(true);
             setTimeout(() => setShowLoader(false), 1000);

             await VendeurAPI.storeTickets(ticket);
             toast.success("Billet ajouté avec succès");
             window.location.reload();
         }catch({response}){
             setTimeout(() => setShowLoader(false), 1000);
             const {violations} = response.data;

             if(violations){
                 const apiErrors = {};
                 Object.keys(violations).forEach(key => {
                     apiErrors[key] = violations[key]
                 });

                 setErrors(apiErrors);
                 toast.error("Une erreur est servenue dans le formulaire d'ajout de billet !");
             }
         }
     };

     function calculSubTotal(data) {
         let tCDF = 0;
         data.map((ticket) => tCDF += ticket.amount);
         return tCDF
     }

     function calculCDF(data) {
         let tCDF = 0;
         data.map((item) => tCDF += item.totalCDF);
         return tCDF
     }

     function calculUSD(data) {
         let tUSD = 0;
         data.map((item) => tUSD += item.totalUSD);
         return tUSD
     }

     const ticketsByDestination = (data) => {
         const destinations = data.tickets.map((key, value) => key = key.destination);
         let data_table = [];
         destinations.forEach((val) => {
             if (data_table[val]) {
                 data_table[val] += 1;
             } else {
                 data_table[val] = 1;
             }
         });
         return data_table;
     };



     let counter = 1;


     return (
         <div className="container">
             <div className="row">
                 <div className="col-md-5">
                     <div className="card">
                         <div className="card-body">
                             <h3 className="card-title text-success">BUS DE TRANSITE DISPONIBLES</h3>
                             <ol className="list-group list-group-numbered">
                                 {
                                     buses ? (buses.map((bus) => (
                                         <li key={bus.id} className="list-group-item d-flex justify-content-between align-items-start">
                                             <div className="ms-2 me-auto">
                                                 <div className="fw-bold">{bus.bus_number} | {bus.bus_numberplate}</div>
                                             </div>
                                             <i className="bi bi-pin-map"></i> : <b>{bus.to}</b>
                                             <i className="bi bi-clock"></i> : <b>{bus.bus_hour}</b>
                                             <i className="bi bi-person"></i> : <b>
                                             {bus.bus_number_of_seats - bus.tickets.length}
                                         </b>
                                         </li>
                                     ))) : (<></>)
                                 }
                             </ol>
                         </div>
                     </div>
                     <div className="card">
                         <form onSubmit={handleSubmit}>
                             <div className="card-body">
                                 <h3 className="card-title text-success">Billet bus de transite</h3>

                                 {!loading && <Select name="assignment_id"
                                                      label={buses && buses.length > 0 ?
                                                          (<>Bus : <i className="mt-2 mx-3 bi bi-check2-all text-success"></i></>) :
                                                          (<>Bus : <i className="mt-2 mx-3 bi bi-x-circle-fill text-danger"></i></>)}
                                                      value={ticket.assignment_id}
                                                      error={errors.assignment_id}
                                                      onChange={handleChange}>

                                     <option value="">Choisir</option>
                                     {buses && buses.map((bus) => (
                                         <option key={bus.id} value={bus.id}>N°:{bus.bus_number} /
                                             Plq: {bus.bus_numberplate} -Dest : {bus.to}</option>
                                     ))
                                     }
                                 </Select>}

                                 {!loading && <Select name="destination" label="Destination" value={ticket.destination}
                                                      error={errors.destination} onChange={handleChange}>
                                     <option value="">Choisir</option>
                                     {trajs && trajs.map(traj =>
                                         <option key={traj.id} value={traj.id}>{traj.to}</option>)
                                     }
                                 </Select> }

                                 <div className="row">
                                     <div className="col-md-8">
                                         <Field
                                             name="customer"
                                             error={errors.customer}
                                             value={ticket.customer}
                                             onChange={handleChange}
                                             label="Nom du client"
                                             placeholder="Client..."
                                             autoComplete="off"
                                         />
                                     </div>
                                     <div className="col-md-4">
                                         <label htmlFor="bonus" className="text-success bold">Bonus KKS ?</label>
                                         <CheckBoxField
                                             name="bonus"
                                             id="bonus"
                                             value={ticket.bonus}
                                             label="Cocher ici !"
                                             onChange={handleChex}
                                             //checked={checked ? "checked" : ""}
                                         />
                                     </div>
                                 </div>

                                 <div className="row">
                                     <div className="col-md-8">
                                         <Field
                                             name="phone"
                                             error={errors.phone}
                                             value={ticket.phone}
                                             onChange={handleChange}
                                             label="Téléphone du client"
                                             placeholder="+243..."
                                             autoComplete="off"
                                         />
                                     </div>
                                 </div>

                                 <MyButton
                                     text="Enregistrer"
                                     onSubmit={handleSubmit}
                                     loading={showLoader}
                                     disabled={showLoader}
                                 />
                                 {/*<Link to="/admin/trajets" className="btn btn-danger mx-2 mt-3">Annuler</Link>*/}
                             </div>
                         </form>
                     </div>

                     <div className="card">
                         <div className="card-body">
                             <h3 className="card-title text-primary">Destinations et prix</h3>
                             <ol className="list-group list-group-numbered">
                                 {
                                     trajs ? (trajs.map((traj) => (
                                         <li key={traj.id} className="list-group-item d-flex justify-content-between align-items-start">
                                             <div className="ms-2 me-auto">
                                                 <div className="fw-bold">{traj.to}</div>
                                             </div>
                                             <span className="badge bg-success bg-opacity-75 rounded-1">{traj.price} CDF</span>
                                         </li>
                                     ))) : (<></>)
                                 }
                             </ol>
                         </div>
                     </div>
                 </div>
                 <div className="col-md-7">
                     <div className="card">
                         <div className="card-body">
                             <h2 className="card-title">
                                 Les ventes du jour - Total : <b className="text-primary">{dailyTickets ? dailyTickets.length : 0} </b>
                                 Bus | {total ? (<><b className="text-success">{total}</b> CDF</>):(<>0 CDF</>)}
                             </h2>

                             <div className="row mb-3">
                                 <div className="col-md-6">
                                     <div className="form-group mt-3">
                                         <label htmlFor="ByAll" className="text-dark">
                                             Recherche par Bus
                                         </label>
                                         <input type="text"
                                                onChange={handleSearch}
                                                value={search}
                                                name="ByAll"
                                                id="ByAll"
                                                className="form-control"
                                                placeholder="Rechercher ..."
                                         />
                                     </div>
                                 </div>
                             </div>


                             {!loading && <ol className="list-group list-group-numbered">
                                 {paginatedTickets.map((bus) => {
                                     let counti = 1;
                                     return (<div key={bus.id}>
                                             <li className="list-group-item d-flex justify-content-between align-items-start">
                                                 <div className="ms-2 me-auto">
                                                     <div className="fw-bold"><span className="text-success">{bus.bus_numberplate}</span></div>
                                                     Destination : <b>{bus.to}</b>, Depart : {bus.bus_hour}, <br/>Chauffeur : <b>{bus.driver_name}</b>,
                                                     Escorteur : <b>{bus.manager_name}</b><br/>
                                                     <span className="">
                                                <b className="text-info">{calculSubTotal(bus.tickets)} Fc - </b>
                                            </span>
                                                     <span>
                                                <b className="text-danger">Dépense : {calculCDF(bus.bus_spents)} Fc + {calculUSD(bus.bus_spents)} $</b> =
                                                <b className="text-success"> {calculSubTotal(bus.tickets) - (calculCDF(bus.bus_spents) + ((calculUSD(bus.bus_spents) * setting.currency)))} Fc</b>
                                            </span>
                                                     <br/>
                                                     <div className="fw-bold">
                                                         {ticketsByDestination(bus).Lubumbashi && (<span className="badge bg-primary mx-1">
                                                Lubumbashi : {ticketsByDestination(bus).Lubumbashi}</span>)}

                                                         {ticketsByDestination(bus).Kolwezi && (<span className="badge bg-success mx-1">
                                                Kolwezi : {ticketsByDestination(bus).Kolwezi}</span>)}

                                                         {ticketsByDestination(bus).Likasi && (<span className="badge bg-info mx-1">
                                                Likasi : {ticketsByDestination(bus).Likasi}</span>)}

                                                         {ticketsByDestination(bus).Fungurume && (<span className="badge bg-dark mx-1">
                                                Fungurume : {ticketsByDestination(bus).Fungurume}</span>)}

                                                         {ticketsByDestination(bus).Kasumbalesa && (<span className="badge bg-danger mx-1">
                                                Kasumbalesa : {ticketsByDestination(bus).Kasumbalesa}</span>)}

                                                         {ticketsByDestination(bus).Sakania && (<span className="badge bg-primary mx-1">
                                                Sakania : {ticketsByDestination(bus).Sakania}</span>)}
                                                     </div>
                                                 </div>

                                                 <span className="badge bg-primary rounded-pill mx-2">{bus.tickets.length} tickets / {bus.bus_number_of_seats}</span>
                                                 <a className="badge bg-success" data-bs-toggle="collapse"
                                                    href={"#tickets"+bus.id} role="button" aria-expanded="false"
                                                    aria-controls={"tickets"+bus.id}>Voir <i className="bi bi-eye"></i> </a>
                                             </li>

                                             <div className="collapse multi-collapse" id={"tickets"+bus.id}>
                                                 <div className="card card-body">
                                                     <table className="table table-hover table-striped">
                                                         <thead>
                                                         <tr>
                                                             <th scope="col">#</th>
                                                             <th scope="col">Numero</th>
                                                             <th scope="col">Client</th>
                                                             <th scope="col">Destination</th>
                                                             <th scope="col">Montant</th>
                                                             <th scope="col">Bonus</th>
                                                             <th scope="col">Option</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                         {bus.tickets
                                                             .sort((a, b) => b.id - a.id)
                                                             .map((ticket) => (
                                                                 <tr key={ticket.id}>
                                                                     <td>{counti++}</td>
                                                                     <td><b>{ticket.bus_plaque}</b></td>
                                                                     <td><b className="text-success">{ticket.customer}</b></td>
                                                                     <td>{ticket.destination}</td>
                                                                     <td>{ticket.amount} FC</td>
                                                                     <td>{ticket.bonus === 1 ? (<b className="badge text-bg-success">Oui</b>)
                                                                         :(<b className="badge text-bg-dark">Non</b>)}
                                                                     </td>
                                                                     <td>
                                                                         <div className="btn-group">
                                                                             <button className="btn btn-primary">
                                                                                 <PrintComponent plaque={bus.bus_numberplate} hour={bus.bus_hour} setting={setting} ticket={ticket}/>
                                                                             </button>
                                                                         </div>
                                                                     </td>

                                                                 </tr>
                                                             ))}
                                                         </tbody>
                                                     </table>
                                                 </div>
                                             </div>
                                         </div>
                                     )
                                 })}

                             </ol>}

                             {loading && <TableLoader width={300} height={350} />}

                             {/*{itemsPerPage < filteredTickets.length &&*/}
                             {/*<Pagination*/}
                             {/*currentPage={currentPage}*/}
                             {/*itemsPerPage={itemsPerPage}*/}
                             {/*length={filteredTickets.length}*/}
                             {/*onPageChange={handleChangeCurrentPage}*/}
                             {/*/>*/}
                             {/*}*/}

                         </div>
                     </div>
                 </div>
             </div>
         </div>
     );
 };

 export default NewVenteTransite;
