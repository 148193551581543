import React,{useState} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Fret_header from "../headers/Fret_header";
import FretSidebar from "../sidebars/FretSidebar";
import useAuth from "../../../hooks/usesAuth";
import AuthAPI from "../../../Services/auth/authAPI";

export const FretLayout = ({ allowedRoles }) => {


    const { auth } = useAuth();
    const location = useLocation();


    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <Fret_header />
            <FretSidebar />
            <main id="main" className="main">
                <Outlet />
            </main>

        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default FretLayout;
