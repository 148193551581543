import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from 'moment';

class ComponentToPrint extends React.Component {

    render() {
        const data = this.props.data;
        const param = this.props.param;
        const critere = this.props.critere;

        const mystyle = {
            padding: "5px",
            marginRight: "5px",
            marginLeft: "5px",
            fontFamily: "Arial"
        };

        let counter = 1;

        return (
            <div className="container" style={mystyle}>
                <div className="row">

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h2 className="card-title">KKS EXPRESS, RAPPORT DE VENTE DE BILLETS<br/>
                                            Total : <b className="text-primary">{data ? data.length : 0} </b></h2>
                                        {critere && (<h4>Recherche par point de vente, vendeur ou Bus :
                                            <b className="text-primary"> {critere}</b></h4>)}
                                    </div>
                                </div>

                                <hr/>
                                <table className="table table-sm table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">De</th>
                                        <th scope="col">Vers</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Prix</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Vendeur</th>
                                        <th scope="col">PdV</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((ticket) => (
                                        <tr key={ticket.id}>
                                            <th scope="row">{counter++}</th>
                                            <td>{ticket.customer}</td>
                                            <td>{ticket.from}</td>
                                            <td>{ticket.destination}</td>
                                            <td>{ticket.busNumero}</td>
                                            <td>{
                                                ticket.genre === 0 ? (<b className="badge text-bg-success">Direct</b>)
                                                    :(<b className="badge text-bg-primary">Transite</b>)
                                            }</td>
                                            <td>{ticket.amount} CDF</td>
                                            <td>{Moment(ticket.created_at).format("DD/MM/YYYY")}</td>
                                            <td>{ticket.name}</td>
                                            <td>{ticket.pos_name}</td>
                                        </tr>
                                    ))
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
export default ComponentToPrint;
