import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ajax from "../../Services/fetchService";
import {useUser} from "../../Services/userProvider";

const ManagerBusManagerIndex = () => {

    const user = useUser();
    const [bus_managers, setBusManagers] = useState(null);

    useEffect(() => {
        ajax("/api/v1/manager/users/bus_managers", "GET", user.jwt).then(
            (response) => {
                setBusManagers(response.bus_managers);
            })
    },[]);

    let count = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les Escorteurs</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/manager">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les Escorteurs</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Total {bus_managers ? bus_managers.length : 0}</h5>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Telphone</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        bus_managers ? (bus_managers.map((bus_manager) => (
                                            <tr key={bus_manager.id}>
                                                <th scope="row">{count++}</th>
                                                <td>{bus_manager.name}</td>
                                                <td>{bus_manager.email}</td>
                                                <td>{bus_manager.phone}</td>
                                            </tr>
                                        ))) : (<></>)
                                    }

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ManagerBusManagerIndex;
