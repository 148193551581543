import React, { useEffect, useState } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import Field from "../../forms/Field";
import BusesHoursAPI from "../../../apis/adminApi/busHourAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const EditBusHour = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {busHourId} = useParams();

    const [showLoader, setShowLoader] = useState(false);

    const [busHour, setBusHour] = useState({
        bus_hour : "", id : busHourId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        bus_hour : "",
    });


    //Recuperation d'une facture
    const fetchBusHour = async id => {
        try{
            const { bus_hour } = await BusesHoursAPI.find(id);
            setBusHour({ bus_hour });
            setLoading(false);
        }catch(error){
            //console.log(error);
            toast.error("Impossible de charger l'heure de bus démandée !");
            navigate("/admin/busesHours");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchBusHour(busHourId);
    }, [busHourId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setBusHour({...busHour, [name] : value})
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BusesHoursAPI.updateBusHour(busHourId, busHour);
            toast.success("Heure de Bus modifié avec succès");
            setTimeout(() => setShowLoader(false), 1000);
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier une heure de bus</h5>
                                        <Field
                                            name="bus_hour"
                                            error={errors.bus_hour}
                                            value={busHour.bus_hour}
                                            onChange={handleChange}
                                            label="Heure"
                                            placeholder="Heure"
                                        />
                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/busesHours" className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditBusHour;
