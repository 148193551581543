import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Pagination from "../../assets/Pagination";
import {toast} from "react-toastify";
import PvsAPI from "../../../apis/adminApi/posAPI";
import TableLoader from "../../loaders/TableLoader";

const PvsIndex = () => {

    const [pvs, setPvs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchPvs = async () => {
        try{
            const data =  await PvsAPI.findAll();
            setPvs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des pvs !");
        }
    };



    //Au chargement du composant on va chercher les pvs
    useEffect(() => {
        fetchPvs();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des pvs en fonction de la recherche

    const filteredPvs = pvs.filter(
        p =>
            p.pos_name.toLowerCase().includes(search.toLowerCase()) ||
            p.agency.agency_name.toLowerCase().includes(search.toLowerCase()) ||
            p.pos_city.toLowerCase().includes(search.toLowerCase()) ||
            p.pos_address.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedPvs = Pagination.getData(
        filteredPvs,
        currentPage,
        itemsPerPage
    );

    let counter = 1;



    return (
        <>
            <div className="pagetitle">
                <h1>Les pvs</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les Points de vente KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {pvs ? pvs.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/pvs/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter un pv
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Agence</th>
                                        <th scope="col">Ville</th>
                                        <th scope="col">Adresse</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedPvs.map((pv) => (
                                        <tr key={pv.id}>
                                            <th scope="row">{pv.id}</th>
                                            <td>{pv.pos_name}</td>
                                            <td className="text-primary">{pv.agency.agency_name}</td>
                                            <td>{pv.pos_city}</td>
                                            <td>{pv.pos_address}</td>

                                            <td>
                                                <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/pvs/edit/${pv.id}`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredPvs.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredPvs.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PvsIndex;
