import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import PvsAPI from "../../../apis/adminApi/posAPI";
import {toast} from "react-toastify";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import Select from "../../forms/Select";
import AgencyAPI from "../../../apis/adminApi/agencyAPI";

const AddTrajet = () => {
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const [agencies, setAgencies] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showLoader, setShowLoader] = useState(false);

    const [pv, setPv] = useState({
        pos_name : "", pos_city : "", pos_address : "", agency_id:""
    });

    const [errors, setErrors] = useState({
        pos_name : "", pos_city : "", pos_address : "", agency_id:""
    });

    const fetchAgencies = async () => {
        try{
            const data =  await AgencyAPI.findAll();
            setAgencies(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des agencies !");
        }
    };

    useEffect(() => {
        fetchAgencies();
    }, []);

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setPv({...pv, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await PvsAPI.create(pv);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Point de vente ajouté avec succès");
            navigate("/admin/pvs");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du point de vente !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un point de vente</h5>

                                <Field
                                    name="pos_name"
                                    error={errors.pos_name}
                                    value={pv.pos_name}
                                    onChange={handleChange}
                                    label="Nom (agence)"
                                    placeholder="Nom"
                                />

                                <Field
                                    name="pos_city"
                                    error={errors.pos_city}
                                    value={pv.pos_city}
                                    onChange={handleChange}
                                    label="Ville"
                                    placeholder="ville"
                                />


                                <Field
                                    name="pos_address"
                                    error={errors.pos_address}
                                    value={pv.pos_address}
                                    onChange={handleChange}
                                    label="Adresse"
                                    placeholder="Adresse"
                                />

                                <Select name="agency_id" label="Agence" value={pv.agency_id} error={errors.agency_id} onChange={handleChange}>
                                    <option value="">Choisir</option>
                                    {agencies.map(agency =>
                                        <option key={agency.id} value={agency.id}>
                                            {agency.agency_name} - {agency.agency_city}
                                        </option>)
                                    }
                                </Select>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/pvs" className="btn btn-info mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTrajet;
