import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from "moment";

class ComponentToPrintFret extends React.Component {

    render() {
        const fret = this.props.fret;
        const param = this.props.param;
        return (
            <div>
                {fret ? (<div className="ticket ms-3">
                        <center><img  src={logo} alt="logo" /></center>
                        <p className="centered">{param ? param.company_address : ""} <br/>
                            Le {Moment(fret.created_at).format("DD-MM-YYYY, HH:mm")} <br/>
                            Expediteur : <b>{fret.customer}, {fret.phone}</b> <br/>
                            Code : <b style={{fontSize:"19px"}}>{fret.code}</b> <br/>
                            Depart : <b>{fret.city_from}</b> , Destination : <b>{fret.city_to}</b> <br/>
                            Destiantaire : <b>{fret.recipient}, {fret.rec_phone}</b> <br/>
                            <hr/>
                            <b>BON D'ENVOI N° : {fret.id}</b> <br/>
                        </p>
                        <table className="printi table table-striped">
                            <thead>
                            <tr>
                                <th className="description">Designation</th>
                                <th className="description">Nombre</th>
                                <th className="description">P.U</th>
                                <th className="description">S/Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                fret.fret_items ? (fret.fret_items.map((item) =>
                                        (
                                            <tr key={item.id}>
                                                <td>{item.name}</td>
                                                <td>{item.weight}</td>
                                                <td>{item.montant_par_kilo} FC</td>
                                                <td>{item.amount} FC</td>
                                            </tr>
                                        )))
                                    :(<></>)
                            }
                            </tbody>
                        </table>

                        <h6 className="description">Coût ttc  : <b>{fret.total_amount}</b> FC</h6>
                        <br/>
                        <p className="centered">{param && param.company_idnat}<br />
                            {param && param.company_rccm}<br />
                            {param && param.company_impot} </p>
                        <p className="centered">Merci pour votre confiance!</p>
                    </div>):
                    (<></>)}
            </div>
        );
    }
};
export default ComponentToPrintFret;
