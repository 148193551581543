import React from 'react';
import Web_header from "../Web_header";
import {Outlet} from "react-router-dom";

const WebLayout = () => {
    return (
        <>
            <Outlet />
        </>
    );
};

export default WebLayout;