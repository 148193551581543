import React, { useRef} from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrintFret from "./componentToPrintFret";
import {Link} from "react-router-dom";

export default function PrintFretComponent({ color, keyWord, bus, setting }) {

    let componentRef = useRef();


    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <Link className={"btn btn-sm btn-"+color}> {"Impimer le manifeste "+keyWord+" "}
                    <i className="bi bi-printer"></i> </Link>}
                    content={() => componentRef}
                />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                    <ComponentToPrintFret param={setting} keyCity={keyWord} bus={bus} ref={(el) => (componentRef = el)} />
                </div>
            </div>
        </>
    );
}