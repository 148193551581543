import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import ajax from '../../../Services/fetchService';
import {useUser} from "../../../Services/userProvider";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const EditTicket = () => {
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const user = useUser();
    let {ticketId} = useParams();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");
    const [trajs, setTrajets] = useState();
    const [trajectoire, setTrajectoire] = useState();

    const [billet, setBillet] = useState({
        customer : "",
        destination : "",
        id : ticketId
    });

    function updateDomaine(props, value){
        const newBillet = {...billet};
        newBillet[props] = value;
        setBillet(newBillet);
    }

    useEffect(() => {
        ajax(`/api/v1/vendor/getTrajets`, "GET",user.jwt).then(
            (response) => {
                setTrajets(response.trajets);
            }
        );
    },[]);


    function save(){
        return new Promise(resolve => {
            setErrorMsg("");
            setTimeout(() => {
                ajax(`/api/v1/vendor/storeTickets`, "POST", user.jwt, billet).then(
                    (billet) => {

                        if (billet.status === 422) {
                            const errorItems = Object.keys(billet.errors).map( (key, i) => {
                                const message = billet.errors[key][0];
                                //setErrorMsg(key+" : "+message);
                                //setErrorMsg(message);
                                toast.error(message, {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            });
                        }else if(billet.status === 400){
                            //setErrorMsg(billet.error);
                            toast.error(billet.error, {
                                position: toast.POSITION.BOTTOM_CENTER,
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }else{
                            setBillet(billet);
                            navigate("/customer/newVente");
                            toast.success("Operation réussie", {
                                position: toast.POSITION.BOTTOM_CENTER,
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                    });
                resolve();
            }, 3000);
        });
    }

    useEffect(() => {
        ajax(`/api/v1/vendor/tickets/${ticketId}`, "GET",user.jwt).then(
            (billetData) => {
                if (billetData.ticket.customer === null) billetData.ticket.customer = "";
                if (billetData.ticket.destination === null) billetData.ticket.destination = "";
                setBillet(billetData.ticket);
                setTrajectoire(billetData.trajet);
            }
        );
    },[]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {billet ?
                        (
                            <form onSubmit={handleSubmit(save)}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un billet</h5>
                                        <div className="mt-1">
                                            <label htmlFor="customer">Nom du client</label>
                                            <input
                                                id="customer"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => updateDomaine("customer", e.target.value)}
                                                value={billet.customer}
                                            />
                                        </div>

                                        <div className="mt-1">
                                            <label htmlFor="destination">Destination</label>
                                            <select
                                                className="form-control form-select"
                                                name="destination"
                                                value={billet.destination}
                                                onChange={(e) => updateDomaine("destination",e.target.value)}
                                                aria-label="Destination">
                                                {trajectoire ? (<option value={trajectoire.id} selected>{trajectoire.to}</option>):(<></>)}

                                                {
                                                    trajs ? (trajs.map((traj) => (
                                                        <option key={traj.id} value={traj.id}>{traj.to}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>
                                        {errorMsg ? (
                                            <div className="col-12 justify-content-center mt-3 mb-4">
                                                <div className="" style={{ color: "red" }}>
                                                    <i className="bi bi-exclamation-triangle"></i> {errorMsg}
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <button disabled={isSubmitting} className="btn btn-primary mr-1 mt-3">
                                            {isSubmitting &&
                                            <><span className="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>...</>
                                            } Modifier
                                        </button>
                                        <Link to="/customer/newVente" className="btn btn-danger mx-2 mt-3">Annuler</Link>
                                    </div>
                                </div>
                            </form>
                        )
                        :
                        (<div><span>Rien</span></div>)
                    }
                </div>
            </div>
        </div>
    );
};

export default EditTicket;
