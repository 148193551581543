import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import UsersAPI from "../../../apis/adminApi/userAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";

const BusManagerIndex = () => {


    const [busManagers, setBusManagers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchBusManagers = async () => {
        try{
            const data =  await UsersAPI.findABusManagers();
            setBusManagers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des excorteurs !");
        }
    };

    useEffect(() => {
        fetchBusManagers();
    },[]);



    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredBusManagers = busManagers.filter(
        v =>
            v.name.toLowerCase().includes(search.toLowerCase()) ||
            v.email.toLowerCase().includes(search.toLowerCase()) ||
            v.phone.toLowerCase().includes(search.toLowerCase()) ||
            v.city.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedBusManagers = Pagination.getData(
        filteredBusManagers,
        currentPage,
        itemsPerPage
    );



    let count = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les Excorteurs</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les Excorteurs de bus KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {busManagers ? busManagers.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3" to="/admin/users/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter un bus busManager
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="form-group">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Telephone</th>
                                        <th scope="col">Ville de residence</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedBusManagers.map((busManager) => (
                                        <tr key={busManager.id}>
                                            <th scope="row">{count++}</th>
                                            <td>{busManager.name}</td>
                                            <td>{busManager.email}</td>
                                            <td>{busManager.phone}</td>
                                            <td>{busManager.city}</td>

                                            <td>
                                                <Link className="btn btn-sm btn-success mx-1" to={`/admin/users/edit/${busManager.id}`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                    </tbody> }
                                </table>

                                {loading && <TableLoader width={500} height={550} />}

                                {itemsPerPage < filteredBusManagers.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredBusManagers.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BusManagerIndex;

