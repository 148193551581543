import React, { useEffect, useState } from 'react';

import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FretCatalogueAPI from "../../../apis/adminApi/fretCatalogueAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddFretCatalogue = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);

    const [catalogue, setCatalogue] = useState({
        nomination : "", normal_price : "", quantity_r : "", price_r : "", frequency_r : "", percent_r : ""
    });

    const [errors, setErrors] = useState({
        nomination : "", normal_price : "", quantity_r : "", price_r : "", frequency_r : "", percent_r : ""
    });


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setCatalogue({...catalogue, [name] : value})
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await FretCatalogueAPI.create(catalogue);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Prix de catalogue ajouté avec succès");
            navigate("/admin/fret_catalogues");
        }catch({response}){
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du prix de catalogue !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un produit sur la liste catalogue Fret</h5>
                                <div className="row">
                                    <div className="col-md-6">

                                        <Field
                                            name="nomination"
                                            error={errors.nomination}
                                            value={catalogue.nomination}
                                            onChange={handleChange}
                                            label="Nomination"
                                            placeholder="Nomination"
                                        />

                                        <Field
                                            name="normal_price"
                                            error={errors.normal_price}
                                            value={catalogue.normal_price}
                                            onChange={handleChange}
                                            label="Prix normal"
                                            placeholder="000 CDF"
                                            type="number"
                                        />

                                        <Field
                                            name="quantity_r"
                                            error={errors.quantity_r}
                                            value={catalogue.quantity_r}
                                            onChange={handleChange}
                                            label="Quantité pour reduction"
                                            placeholder="000 CDF"
                                            type="number"
                                        />

                                    </div>
                                    <div className="col-md-6">

                                        <Field
                                            name="price_r"
                                            error={errors.price_r}
                                            value={catalogue.price_r}
                                            onChange={handleChange}
                                            label="Prix en reduction"
                                            placeholder="000 CDF"
                                            type="number"
                                        />

                                        <Field
                                            name="frequency_r"
                                            error={errors.frequency_r}
                                            value={catalogue.frequency_r}
                                            onChange={handleChange}
                                            label="Frequnce du client pour redution"
                                            placeholder="00"
                                            type="number"
                                        />

                                        <Field
                                            name="percent_r"
                                            error={errors.percent_r}
                                            value={catalogue.percent_r}
                                            onChange={handleChange}
                                            label="Rémise en pourcentage"
                                            placeholder="00"
                                            type="number"
                                        />

                                    </div>
                                </div>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/fret_catalogues" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFretCatalogue;
