import axios from "axios";
import {FRET_CATALOGUES_API} from "../../config";
import Cache from "../cache";

async function findAllFretCatalogues() {
    const cachedAdminAllFretCatalogues = await Cache.get("AdminAllFretCatalogues");
    if(cachedAdminAllFretCatalogues) return cachedAdminAllFretCatalogues;
    return axios
        .get(FRET_CATALOGUES_API)
        .then(response => {
            const catalogues = response.data.catalogues;
            Cache.set("AdminAllFretCatalogues", catalogues);
            return catalogues;
        });
}




async function find(id){

    const cachedFretCatalogues = await Cache.get("AdminAllFretCatalogues." + id);
    if(cachedFretCatalogues) return cachedFretCatalogues;
    return axios.get(FRET_CATALOGUES_API + "/" + id)
        .then(response => {
            const catalogue = response.data.catalogue;
            Cache.set("AdminAllFretCatalogues." + id, catalogue);
            return catalogue;
        });
}


function updateFretCatalogue(id, catalogue){

    return axios.post(FRET_CATALOGUES_API + "/store", {...catalogue, id: id})
        .then(async response => {
            const cachedFretCatalogues = await Cache.get("AdminAllFretCatalogues");

            const cachedCatalogue = await Cache.get("AdminAllFretCatalogues." + id);

            if(cachedCatalogue){
                Cache.set("AdminAllFretCatalogues." + id, response.data.catalogue);
            }

            if(cachedFretCatalogues){
                const index = cachedFretCatalogues.findIndex(b => b.id === +id);
                cachedFretCatalogues[index] = response.data.catalogue;
            }

            return response;
        });
}

function create(catalogue){

    return axios.post(FRET_CATALOGUES_API + "/store", catalogue)
        .then(async response => {
            const cachedFretCatalogues = await Cache.get("AdminAllFretCatalogues");

            if(cachedFretCatalogues){
                Cache.set("AdminAllFretCatalogues", [...cachedFretCatalogues, response.data.catalogue]);
            }

            return response;
        });
}



export default {
    findAllFretCatalogues,
    find,
    create,
    updateFretCatalogue
};