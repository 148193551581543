import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import AgencyAPI from "../../../apis/adminApi/agencyAPI";
import {toast} from "react-toastify";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddAgency = () => {
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);

    const [agency, setAgency] = useState({
        agency_name : "", agency_city : "", agency_address : ""
    });

    const [errors, setErrors] = useState({
        agency_name : "", agency_city : "", agency_address : ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAgency({...agency, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await AgencyAPI.create(agency);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Agence ajouté avec succès");
            navigate("/admin/agencies");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter une agence</h5>

                                <Field
                                    name="agency_name"
                                    error={errors.agency_name}
                                    value={agency.agency_name}
                                    onChange={handleChange}
                                    label="Nom (agence)"
                                    placeholder="Nom"
                                />

                                <Field
                                    name="agency_city"
                                    error={errors.agency_city}
                                    value={agency.agency_city}
                                    onChange={handleChange}
                                    label="Ville"
                                    placeholder="ville"
                                />


                                <Field
                                    name="agency_address"
                                    error={errors.agency_address}
                                    value={agency.agency_address}
                                    onChange={handleChange}
                                    label="Adresse"
                                    placeholder="Adresse"
                                />

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/agencies" className="btn btn-info mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAgency;
