import React from 'react';


const Field = ({name, label, value, onChange, placeholder = "", type = "text", error = "",
                   autoComplete="false", disabled="" }) =>
(<div className="mb-3">
    <label htmlFor={name} className="form-label">{label}</label>
    <input
        disabled={disabled}
        value={value} 
        onChange={onChange}
        name={name} 
        type={type} 
        className={"form-control " + ( error && "is-invalid")}
        id={name} 
        autoComplete={autoComplete}
        placeholder={placeholder || label}
    />
    {error && <div className="invalid-feedback">{error}</div>}
</div> 
);
 
export default Field;