import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const CustomerSidebar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/customer/dashboard' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/dashboard">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>

                <li className="nav-heading">Vente des billets</li>

                <li className="nav-item">
                    <Link className={pathname === '/customer/newVente' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/newVente">
                        <i className="bi bi-pass-fill"></i>
                        <span>Billet Normal</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/customer/newVenteTransite' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/newVenteTransite">
                        <i className="bi bi-pass"></i>
                        <span>Billet de transite</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/customer/newVenteReservation' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/newVenteReservation">
                        <i className="bi bi-pass-fill"></i>
                        <span>Billet de réservation</span>
                    </Link>
                </li>

                <li className="nav-heading">RAPPORT DE VENTE</li>

                <li className="nav-item">
                    <Link className={pathname === '/customer/dailyVentes' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/dailyVentes">
                        <i className="bi bi-play-fill"></i>
                        <span>Ventes d'aujourd'hui</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/customer/allVentes' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/allVentes">
                        <i className="bi bi-play-fill"></i>
                        <span>Toutes mes ventes</span>
                    </Link>
                </li>


                <li className="nav-heading">Depenses de bus</li>


                <li className="nav-item">
                    <Link className={pathname === '/customer/dailyBusSpents' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/dailyBusSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Aujourd'hui</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/customer/allBusSpents' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/allBusSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Toutes mes depenses de bus</span>
                    </Link>
                </li>


                <li className="nav-heading">Autres Depenses</li>


                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/customer/newSpent' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/newSpent">*/}
                        {/*<i className="bi bi-plus-circle-fill"></i>*/}
                        {/*<span>Ajouter une depense</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/customer/dailySpents' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/dailySpents">*/}
                        {/*<i className="bi bi-play-fill"></i>*/}
                        {/*<span>Aujourd'hui</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                <li className="nav-item">
                    <Link className={pathname === '/customer/allSpents' ? 'nav-link ' : 'nav-link collapsed'} to="/customer/allSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Toutes mes autres depenses</span>
                    </Link>
                </li>

            </ul>

            <div className="fixed-bottom mx-4 my-3">
                <button className="btn btn-danger" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-left"></i>
                    <span> Se deconnecter</span>
                </button>
            </div>

        </aside>
    );
};

export default CustomerSidebar;