import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import BusesHoursAPI from "../../../apis/adminApi/busHourAPI";
import {toast} from "react-toastify";
import Pagination from "../../assets/Pagination";
import Moment from "moment/moment";
import TableLoader from "../../loaders/TableLoader";

const BusHourIndex = () => {

    const [busesHours, setBusesHours] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchBusesHours = async () => {
        try{
            const data =  await BusesHoursAPI.findAll();
            setBusesHours(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des heures buses !");
        }
    };



    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchBusesHours();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredBusesHours = busesHours.filter(
        bh =>
            bh.bus_hour.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedBusesHours = Pagination.getData(
        filteredBusesHours,
        currentPage,
        itemsPerPage
    );


    let counter = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les heures buses</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les Heures bus KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {busesHours ? busesHours.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/busesHours/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter une heure de bus
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Heure</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedBusesHours.map((busHour) => (
                                        <tr key={busHour.id}>
                                            <th scope="row">{busHour.id}</th>
                                            <td>{busHour.bus_hour}</td>
                                            <td>
                                                <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/busesHours/edit/${busHour.id}`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredBusesHours.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredBusesHours.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BusHourIndex;
