import React, { useEffect, useState } from 'react';

import {Link, useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";
import {toast} from "react-toastify";
import TrajetsAPI from "../../../apis/adminApi/trajetsAPI";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const AddTrajet = () => {
    const navigate = useNavigate();
    const { formState } = useForm();
    const { isSubmitting } = formState;

    const [trajet, setTrajet] = useState({
        from : "", to : "", price : "", price_fret: 0
    });
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState({
        from : "", to : "", price : "", price_fret: ""
    });

    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setTrajet({...trajet, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await TrajetsAPI.create(trajet);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Trajet ajouté avec succès");
            navigate("/admin/trajets");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du trajet !");
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h5 className="card-title">Ajouter un trajet</h5>

                                <Field
                                    name="from"
                                    error={errors.from}
                                    value={trajet.from}
                                    onChange={handleChange}
                                    label="Provenance (depart)"
                                    placeholder="Ville de provenance"
                                />

                                <Field
                                    name="to"
                                    error={errors.to}
                                    value={trajet.to}
                                    onChange={handleChange}
                                    label="Destination (arrivé)"
                                    placeholder="Ville de destination"
                                />

                                <Field
                                    name="price"
                                    error={errors.price}
                                    value={trajet.price}
                                    onChange={handleChange}
                                    label="Prix du transport en CDF"
                                    type="number"
                                    placeholder="000 CDF"
                                />

                                {/*<Field*/}
                                    {/*name="price_fret"*/}
                                    {/*error={errors.price_fret}*/}
                                    {/*value={trajet.price_fret}*/}
                                    {/*onChange={handleChange}*/}
                                    {/*label="Prix du Kilo (Fret) en CDF"*/}
                                    {/*type="number"*/}
                                    {/*placeholder="000 CDF"*/}
                                {/*/>*/}


                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/admin/trajets" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTrajet;
