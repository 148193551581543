import React,{useState} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Customer_header from "../headers/Customer_header";
import CustomerSidebar from "../sidebars/CustomerSidebar";
import useAuth from "../../../hooks/usesAuth";
import AuthAPI from "../../../Services/auth/authAPI";

export const CustomerLayout = ({ allowedRoles }) => {

    const { auth } = useAuth();
    const location = useLocation();


    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <Customer_header />
            <CustomerSidebar />
            <main id="main" className="main">
                <Outlet />
            </main>

        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default CustomerLayout;
