import React, { useEffect, useState } from 'react';
import {useForm} from "react-hook-form";
import {useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import Field from "../../forms/Field";
import {useNavigate} from "react-router-dom";
import MyButton from "../../assets/MyButton";
import TableRows from "./tableRows";

const NewSpent = () => {

    const navigate = useNavigate();
    let {busSpentId} = useParams();
    const [showLoader, setShowLoader] = useState(false);

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    const [rowsData, setRowsData] = useState([]);
    const [tableColi, setTableColis] = useState([]);

    const addTableRows = ()=>{

        const rowsInput={
            title:'', amount: '', currency: '',
        };
        setRowsData([...rowsData, rowsInput])

    };
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    };

    const [spent, setSpent] = useState({
        lesColis : rowsData
    });


    const handleForChange = (index, event) => {
        const { name, value } = event.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        setSpent({...spent, lesColis : rowsData});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await VendeurAPI.storeSpent(spent);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Depenses ajoutées avec succès");
            //window.location.reload();
            navigate('/customer/dailySpents')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });
                toast.error("Une erreur est servenue dans le formulaire d'ajout de billet !");
            }
        }
    };




    let counter = 1;



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                            <h5 className="card-title">Ajouter depenses</h5>
                                <hr/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Montant</th>
                                                <th>Devise</th>
                                                <th>
                                                    <a className="btn btn-success" onClick={addTableRows} >
                                                        <i className="bi bi-plus-circle"></i> Ajouter une depense
                                                    </a>
                                                </th>
                                            </tr>

                                            </thead>
                                            <tbody>

                                            <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleForChange} />

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4">

                                    </div>
                                </div>

                                {(rowsData[0] && rowsData[0].title !== "" && rowsData[0].amount !== ""
                                    && rowsData[0].currency !== "")
                                && (<MyButton
                                    text="Enregistrer"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />)}

                                {/*<MyButton*/}
                                    {/*text="Enregistrer"*/}
                                    {/*onSubmit={handleSubmit}*/}
                                    {/*loading={showLoader}*/}
                                    {/*disabled={showLoader}*/}
                                {/*/>*/}

                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewSpent;
