import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import ajax from '../../../Services/fetchService';
import {useUser} from "../../../Services/userProvider";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const EditSetting = () => {
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const user = useUser();
    let {busId} = useParams();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");
    const [bus, setBus] = useState({
        bus_code : "",
        bus_numberplate : "",
        bus_seat_number : "",
        id : busId
    });
    const [showLoader, setShowLoader] = useState(false);

    function updateDomaine(props, value){
        const newTrajet = {...bus};
        newTrajet[props] = value;
        setBus(newTrajet);
    }


    function save(){
        return new Promise(resolve => {
            setErrorMsg("");
            setShowLoader(true);
            setTimeout(() => {
                ajax(`/api/v1/admin/buses/store`, "POST", user.jwt, bus).then(
                    (bus) => {

                        if (bus.status === 422) {
                            const errorItems = Object.keys(bus.errors).map( (key, i) => {
                                const message = bus.errors[key][0];
                                //setErrorMsg(key+" : "+message);
                                setErrorMsg(message);
                            });
                        }else if(bus.status === 400){
                            setErrorMsg(bus.error);
                        }else{
                            setBus(bus);
                            navigate("/admin/buses");
                        }

                    });
                resolve();
            }, 3000);
            setTimeout(() => setShowLoader(false), 1000);
        });
    }

    useEffect(() => {
        ajax(`/api/v1/admin/buses/${busId}`, "GET",user.jwt).then(
            (busData) => {
                if (busData.bus.bus_code === null) busData.bus.bus_code = "";
                if (busData.bus.bus_numberplate === null) busData.bus.bus_numberplate = "";
                if (busData.bus.bus_seat_number === null) busData.bus.bus_seat_number = "";
                setBus(busData.bus);
            }
        );
    },[]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {bus ?
                        (
                            <form onSubmit={handleSubmit(save)}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un bus</h5>
                                        <div className="mt-1">
                                            <label htmlFor="bus_code">Code</label>
                                            <input
                                                id="bus_code"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => updateDomaine("bus_code", e.target.value)}
                                                value={bus.bus_code}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor="bus_numberplate">Plaque</label>
                                            <input
                                                id="bus_numberplate"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => updateDomaine("bus_numberplate", e.target.value)}
                                                value={bus.bus_numberplate}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor="bus_seat_number">Nombre de siège</label>
                                            <input
                                                id="bus_seat_number"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => updateDomaine("bus_seat_number", e.target.value)}
                                                value={bus.bus_seat_number}
                                            />
                                        </div>
                                        {errorMsg ? (
                                            <div className="col-12 justify-content-center mt-3 mb-4">
                                                <div className="" style={{ color: "red" }}>
                                                    <i className="bi bi-exclamation-triangle"></i> {errorMsg}
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/buses" className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>
                        )
                        :
                        (<div><span>Rien</span></div>)
                    }
                </div>
            </div>
        </div>
    );
};

export default EditSetting;
