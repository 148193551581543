import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useUser} from "../../../Services/userProvider";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import UserAPI from "../../../apis/adminApi/userAPI";
import PvsAPI from "../../../apis/adminApi/posAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import Select from "../../forms/Select";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const AffectVendeur = () => {

    const [showLoader, setShowLoader] = useState(false);

    const { formState } = useForm();
    const { isSubmitting } = formState;
    let {vendeurId} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [pvs, setPvs] = useState("");
    const [vendeur, setVendeur] = useState({
        name : "",
        pvId : "",
        id : vendeurId
    });

    const [errors, setErrors] = useState({
        name : "",
        pvId : "",
    });

    //Recuperation d'une facture
    const fetchUser = async vendeurId => {
        try{
            const { id, name, email, phone, city, role, actived, pvId } = await UserAPI.findVendor(vendeurId);
            setVendeur({ id, name, email, phone, city, role, actived, pvId });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'utilisateur démandé !");
            navigate("/admin/vendeurs");
        }
    };

    const fetchPvs = async () => {
        try{
            const pvs = await PvsAPI.findAll();
            setPvs(pvs);
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger les points de vente démandés !");
            navigate("/admin/vendeurs");
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchPvs()
    },[]);


    useEffect(() => {
        setLoading(true);
        fetchUser(vendeurId);
    },[vendeurId]);



    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setVendeur({...vendeur, [name] : value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await UserAPI.affectToPv(vendeur, vendeurId);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Utilisateur affecté avec succès");
            navigate("/admin/fretOperateurs");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {error} = response.data;
            if (error) {
                toast.error(error);
            }
        }
    };

    let redirect = "";
    if (vendeur.role === "ROLE_VENDOR") {
        redirect = "vendeurs";
    }else if (vendeur.role === "ROLE_FRET") {
        redirect = "fretOperateurs";
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un vendeur</h5>

                                        <Field
                                            name="name"
                                            value={vendeur.name}
                                            onChange={handleChange}
                                            label="Nom Complet"
                                            disabled="disabled"
                                        />

                                        {pvs && <Select name="pvId" label="Point de vente" value={vendeur.pvId} error={errors.pvId} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {pvs.map(pv =>
                                                <option key={pv.id} value={pv.id}>
                                                    {pv.pos_name} - {pv.pos_city}
                                                </option>)
                                            }
                                        </Select>}



                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to={`/admin/${redirect}`} className="btn btn-info mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>}
                </div>
            </div>
        </div>
    );
};

export default AffectVendeur;
