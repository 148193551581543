import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';

import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import AgencyAPI from "../../../apis/adminApi/agencyAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const EditAgency = () => {
    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {agencyId} = useParams();

    const [showLoader, setShowLoader] = useState(false);

    const [agency, setAgency] = useState({
        agency_name : "", agency_city : "", agency_address : "", id : agencyId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        agency_name : "", agency_city : "", agency_address : ""
    });


    //Recuperation
    const fetchAgency = async id => {
        try{
            const { agency_name, agency_city, agency_address } = await AgencyAPI.find(id);
            setAgency({ agency_name, agency_city, agency_address });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le point de vente démandé !");
            navigate("/admin/agencies");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchAgency(agencyId);
    }, [agencyId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAgency({...agency, [name] : value})
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await AgencyAPI.updatePv(agencyId, agency);
            toast.success("Point de vente modifié avec succès");
            setTimeout(() => setShowLoader(false), 1000);
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du agency !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier une agence</h5>

                                        <Field
                                            name="agency_name"
                                            error={errors.agency_name}
                                            value={agency.agency_name}
                                            onChange={handleChange}
                                            label="Nom de l'agence"
                                        />

                                        <Field
                                            name="agency_city"
                                            error={errors.agency_city}
                                            value={agency.agency_city}
                                            onChange={handleChange}
                                            label="Ville"
                                        />

                                        <Field
                                            name="agency_address"
                                            error={errors.agency_address}
                                            value={agency.agency_address}
                                            onChange={handleChange}
                                            label="Adresse"
                                        />





                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/agencies" className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditAgency;
