import React from "react";
import CheckBoxField from "../../forms/CheckBoxField";

function TableRows({catalogues, rowsData, deleteTableRows, handleChange}) {


    return(

        rowsData.map((data, index)=>{
            const {colisName, qty, price, brittle, accompanied} = data;
            return(

                <tr key={index}>
                    <td>

                        <input
                            placeholder="Nom du colis"
                            type="text"
                            value={colisName}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="colisName"
                            className="form-control"
                        />
                        {/*<select*/}
                            {/*className="form-control form-select"*/}
                            {/*name="colisName"*/}
                            {/*value={colisName}*/}
                            {/*onChange={(evnt)=>(handleChange(index, evnt))}*/}
                            {/*aria-label="Nom du colis">*/}
                            {/*<option value="">Choisir</option>*/}
                            {/*{*/}
                                {/*catalogues ? (catalogues.map((catalogue) => (*/}
                                    {/*<option key={catalogue.id} value={catalogue.nomination}>{catalogue.nomination}</option>*/}
                                {/*))) : (<></>)*/}
                            {/*}*/}
                        {/*</select>*/}
                    </td>

                    <td>
                        <input
                            placeholder="00"
                            type="number"
                            value={qty}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="qty"
                            className="form-control"
                        />
                    </td>

                    <td>
                        <input
                            placeholder="00"
                            type="number"
                            value={price}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="price"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <select
                            className="form-control form-select"
                            name="brittle"
                            value={brittle}
                            onChange={(evnt)=>(handleChange(index, evnt))}
                            aria-label="Fragile ?">
                            <option value="">Choisir</option>
                            <option value="0">Non</option>
                            <option value="1">Oui</option>

                        </select>
                    </td>
                    <td>
                        <select
                            className="form-control form-select"
                            name="accompanied"
                            value={accompanied}
                            onChange={(evnt)=>(handleChange(index, evnt))}
                            aria-label="Accompagné ?">
                            <option value="">Choisir</option>
                            <option value="1">Oui</option>
                            <option value="0">Non</option>

                        </select>
                    </td>
                    <td><a className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>
                        <i className="bi bi-trash-fill"></i>
                    </a></td>
                </tr>

            )
        })

    )

}

export default TableRows;