import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from "moment";

class ComponentToPrintFret extends React.Component {

    render() {
        const bus = this.props.bus;
        const param = this.props.param;
        const keyCity = this.props.keyCity;
        let counter = 1;
        let counter2 = 1;

        const mystyle = {
            color: "black",
            padding: "30px 30px",
            fontSize: "15px",
        };

        const ticketsByProvenance = (data) => {
            const destinations = data.tickets.map((key, value) => key = key.from);
            let data_table = [];
            destinations.forEach((val) => {
                if (data_table[val]) {
                    data_table[val] += 1;
                } else {
                    data_table[val] = 1;
                }
            });
            return data_table;
        };

        return (
            <>
                {bus ? (<div style={mystyle}>
                        <center><img  src={logo} alt="logo" /></center>
                        <p>{param ? param.company_address : ""} <br/>
                            Date : Le {Moment(bus.created_at).format("DD-MM-YYYY")} <br/>
                            Bus : <b>{bus.bus_numberplate}</b> <br/>
                            Provenance : <b>{bus.from}</b>, Destination : <b>{bus.to}</b>, Depart : <b>{bus.bus_hour}</b> <br/>
                            Chauffeur : <b>{bus.driver_name}</b>, Escorteur : <b>{bus.manager_name}</b>
                        </p><br/>
                        <hr/>
                        <h3><b>MANIFEST TICKETS</b></h3>
                        <div className="fw-bold">
                            {(ticketsByProvenance(bus).Lubumbashi && keyCity === "Lubumbashi") && (<span style={{fontSize: "20px"}} className="badge bg-success mx-1">
                                                Lubumbashi : {ticketsByProvenance(bus).Lubumbashi}</span>)}

                            {(ticketsByProvenance(bus).Kolwezi && keyCity === "Kolwezi") && (<span style={{fontSize: "20px"}} className="badge bg-primary mx-1">
                                                Kolwezi : {ticketsByProvenance(bus).Kolwezi}</span>)}

                            {(ticketsByProvenance(bus).Likasi && keyCity === "Likasi") && (<span style={{fontSize: "20px"}} className="badge bg-dark mx-1">
                                                Likasi : {ticketsByProvenance(bus).Likasi}</span>)}

                            {(ticketsByProvenance(bus).Fungurume && keyCity === "Fungurume") && (<span style={{fontSize: "20px"}} className="badge bg-danger mx-1">
                                                Fungurume : {ticketsByProvenance(bus).Fungurume}</span>)}

                            {(ticketsByProvenance(bus).Kasumbalesa && keyCity === "Kasumbalesa") && (<span style={{fontSize: "20px"}} className="badge bg-dark mx-1">
                                                Kasumbalesa : {ticketsByProvenance(bus).Kasumbalesa}</span>)}

                            {(ticketsByProvenance(bus).Sakania && keyCity === "Sakania") && (<span style={{fontSize: "20px"}} className="badge bg-info mx-1">
                                                Sakania : {ticketsByProvenance(bus).Sakania}</span>)}
                        </div>
                        <hr/>
                        <h3><b>MANIFEST FRETS</b></h3>

                        <table className="table table-hover table-striped">
                            <thead>
                            <tr>
                                <th scope="col">N°</th>
                                <th scope="col">Exp</th>
                                <th scope="col">Tél</th>
                                <th scope="col">Code</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Provenance</th>
                                <th scope="col">Destinataire</th>
                                <th scope="col">Tél</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bus.colis && bus.colis.map((fret) => (
                                keyCity === fret.city_from ? (<tr key={fret.id}>
                                    <td>{counter2++}</td>
                                    <td>{fret.expediteur}</td>
                                    <td>{fret.expediteur_phone}</td>
                                    <td><b>{fret.reference}</b></td>
                                    <td>{fret.name}</td>
                                    <td className="text-center"><b>{fret.code}</b></td>
                                    <td className="text-center"><b>{fret.city_from}</b></td>
                                    <td>{fret.recipient}</td>
                                    <td>{fret.recipient_phone}</td>

                                </tr>) : (<></>)

                            ))}
                            </tbody>
                        </table>

                    </div>):
                    (<></>)}
            </>
        );
    }
};
export default ComponentToPrintFret;
