import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/admin/Dashboard";
import LoginPage from "./components/Auth/Login";
import AdminLayout from "./components/includes/layouts/adminLayout";
import CustomerDashboard from "./components/customer/dashboard";
import CustomerLayout from "./components/includes/layouts/customerLayout";
import WebLayout from "./components/includes/layouts/web_layout";

import TrajetIndex from "./components/admin/trajets/index";
import AddTrajet from "./components/admin/trajets/add_trajet";
import EditTrajet from "./components/admin/trajets/edit_trajet";

import BusIndex from "./components/admin/bus/index";
import AddBus from "./components/admin/bus/add_bus";
import EditBus from "./components/admin/bus/edit_bus";

import VendeurIndex from "./components/admin/users/index";
import AffectVendeur from "./components/admin/users/affecte_vendeur";
import ComptableIndex from "./components/admin/users/indexComptable";
import AddUser from "./components/admin/users/add_user";
import EditUser from "./components/admin/users/edit_user";

import PvsIndex from "./components/admin/pvs/index";
import AddPv from "./components/admin/pvs/add_pv";
import EditPv from "./components/admin/pvs/edit_pv";


import SettingIndex from "./components/admin/settings";
import AddSetting from "./components/admin/settings/add_setting";
import EditSetting from "./components/admin/settings/edit_setting";

import RegisterPage from "./components/Auth/Register";
import ValidateUserPage from "./components/Auth/Register/validate_user";
import ForgotPasswordPage from "./components/Auth/Login/forgot_password";
import ResetPasswordPage from "./components/Auth/Login/reset_password";

import NewVente from "./components/customer/ventes/newVente";
import PrintComponent from "./components/customer/ventes/printer";
import EditTicket from "./components/customer/ventes/edit_ticket";
import DailyVentes from "./components/customer/ventes/dailyVentes";
import AllVentes from "./components/customer/ventes/allVentes";
import ComponentToPrintFret from "./components/operateur_fret/frets/componentToPrint";
import EditFret from "./components/operateur_fret/frets/edit_Fret";
import AllFrets from "./components/operateur_fret/frets/allFrets";
import DailyFrets from "./components/operateur_fret/frets/dailyFrets";
import NewFret from "./components/operateur_fret/frets/newFret";
import RapportBilletterie from "./components/admin/rapports/billetterie";
import RapportFret from "./components/admin/rapports/frets";
import ManagerIndex from "./components/admin/users/indexManager";
import BusManagerIndex from "./components/admin/users/indexBusManager";
import DriversIndex from "./components/admin/users/indexDriver";
import FretOperatorIndex from "./components/admin/users/indexFrets";
import ManagerLayout from "./components/includes/layouts/managerLayout";
import ManagerDashboard from "./components/manager/dashboard/index";
import ManagerBus from "./components/manager/bus";
import AddAvailablity from "./components/manager/availablity";
import Allavalablities from "./components/manager/Allavailablities";
import ManagerDriversIndex from "./components/manager/indexDrivers";
import ManagerBusManagerIndex from "./components/manager/indexBusManager";
import EditAvailablity from "./components/manager/edit_availablity";
import AddAssigngment from "./components/manager/addAssignmet";
import AllAssignments from "./components/manager/AllAssigngment";
import BusHourIndex from "./components/admin/busHour";
import AddBusHour from "./components/admin/busHour/add_busHour";
import EditBusHour from "./components/admin/busHour/edit_busHour";
import NewVenteReservation from "./components/customer/ventes/newVenteReservation";
import NewVenteTransite from "./components/customer/ventes/newVenteTransite";
import AddTransiteAssigngment from "./components/manager/addTransiteAssignment";
import AddReservationAssigngment from "./components/manager/addReservationAssignment";
import FretCatalogueIndex from "./components/admin/fretCatalogues";
import EditFretCatalogue from "./components/admin/fretCatalogues/edit_cat";
import AddFretCatalogue from "./components/admin/fretCatalogues/add_cat";
import FretLayout from "./components/includes/layouts/FretLayout";
import FretDashboard from "./components/operateur_fret/dashboard";
import EditAssignment from "./components/manager/editAssignment";
import AuthAPI from "./Services/auth/authAPI";
import RapportDailyBilletterie from "./components/admin/rapports/dailyBilletterie";
import RapportDailyFret from "./components/admin/rapports/dailyfrets";
import DailyBusSpents from "./components/customer/ventes/dailyBusSpents";
import AllBusSpents from "./components/customer/ventes/allBusSpents";
import NewBusSpent from "./components/customer/ventes/newBusSpent";
import DailyAdminBusSpents from "./components/admin/rapports/dailyAdminBusSpents";
import AllAdminBusSpents from "./components/admin/rapports/allAdminBusSpents";
import NewSpent from "./components/customer/ventes/newSpent";
import DailySpents from "./components/customer/ventes/dailySpents";
import AllSpents from "./components/customer/ventes/allSpents";
import AllAdminSpents from "./components/admin/rapports/allAdminSpents";
import DailyAdminSpents from "./components/admin/rapports/dailyAdminSpents";
import ManifestLayout from "./components/includes/layouts/manifestLayout";
import AddSheduledAvailablity from "./components/manager/addSheduledAvailabilities";
import AddSheduledAssigngment from "./components/manager/addSheduledAssignmet";
import DailyFretsByBus from "./components/manifest/pages/byBusdaily";
import EmbarquerFret from "./components/manifest/pages/embarquerFret";
import ManifestDashboard from "./components/manifest/dashboard";
import ManifestIndex from "./components/admin/users/indexManifest";
import DailyReceivedFrets from "./components/manifest/pages/dailyReceivedFrets";
import ReceivedFret from "./components/manifest/pages/receivedFret";
import FretSDelivereds from "./components/manifest/pages/deliverdsFret";
import DailySumary from "./components/admin/rapports/dailySamary";
import AllBusManifest from "./components/manifest/pages/allBusManifest";
import SentFrets from "./components/manifest/pages/sentFret";
import DailySumaryByCity from "./components/admin/rapports/dailySamaryByCity";
import RapportBilletterieByCity from "./components/admin/rapports/billetterieByCity";
import RapportDailyBilletterieByCity from "./components/admin/rapports/dailyBilletterieByCity";
import RapportDailyFretByCity from "./components/admin/rapports/dailyfretsByCity";
import RapportFretByCity from "./components/admin/rapports/fretsByCity";
import DailyAdminBusSpentsByCity from "./components/admin/rapports/dailyAdminBusSpentsByCity";
import EditAgency from "./components/admin/agency/edit_Agency";
import AgencyIndex from "./components/admin/agency";
import AddAgency from "./components/admin/agency/add_Agency";
import UpdateUserPassword from "./components/admin/users/updateUserPassword";
import RapportWeeklyFretByCity from "./components/admin/rapports/weeklyfretsByCity";
import RapportWeeklyFret from "./components/admin/rapports/weeklyFrets";
import ShareBus from "./components/customer/ventes/shareBus";
import AllAdminBusSpentsByCity from "./components/admin/rapports/allAdminBusSpentsByCity";
import WeeklyAdminBusSpents from "./components/admin/rapports/weeklyAdminBusSpents";
import WeeklyAdminBusSpentsByCity from "./components/admin/rapports/weeklyAdminBusSpentsByCity";
import PassengersIndex from "./components/admin/users/indexPassengers";

AuthAPI.setup();

function App() {

    return (
    <>
        <HashRouter>
            <Routes>

                <Route path="/" element={<WebLayout />}>

                    <Route index element={<LoginPage />} />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="reset-password/:username" element={<ResetPasswordPage />} />
                    <Route path="validate-account/:username" element={<ValidateUserPage />} />

                </Route>

                <Route path="/admin/" element={<AdminLayout allowedRoles={["ROLE_ADMIN"]} />}>

                    <Route index element={<Dashboard />}/>
                    {/*<Route path="dashboard" element={<Dashboard />}/>*/}

                    <Route path="buses" element={<BusIndex />} />
                    <Route path="buses/add" element={<AddBus />} />
                    <Route path="buses/edit/:busId" element={<EditBus />} />

                    <Route path="trajets" element={<TrajetIndex />} />
                    <Route path="trajets/add" element={<AddTrajet />} />
                    <Route path="trajets/edit/:trajetId" element={<EditTrajet />} />



                    <Route path="busesHours" element={<BusHourIndex />} />
                    <Route path="busesHours/add" element={<AddBusHour />} />
                    <Route path="busesHours/edit/:busHourId" element={<EditBusHour />} />

                    <Route path="agencies" element={<AgencyIndex />} />
                    <Route path="agencies/add" element={<AddAgency />} />
                    <Route path="agencies/edit/:agencyId" element={<EditAgency />} />

                    <Route path="pvs" element={<PvsIndex />} />
                    <Route path="pvs/add" element={<AddPv />} />
                    <Route path="pvs/edit/:pvId" element={<EditPv />} />


                    <Route path="fret_catalogues" element={<FretCatalogueIndex />} />
                    <Route path="fret_catalogues/add" element={<AddFretCatalogue />} />
                    <Route path="fret_catalogues/edit/:catId" element={<EditFretCatalogue />} />

                    <Route path="settings" element={<SettingIndex />} />
                    <Route path="settings/add" element={<AddSetting />} />
                    <Route path="settings/edit/:settingId" element={<EditSetting />} />

                    <Route path="vendeurs" element={<VendeurIndex />} />
                    <Route path="fretOperateurs" element={<FretOperatorIndex />} />
                    <Route path="manifests" element={<ManifestIndex />} />
                    <Route path="vendeurs/affecteToPv/:vendeurId" element={<AffectVendeur />} />
                    <Route path="admins" element={<ComptableIndex />} />
                    <Route path="managers" element={<ManagerIndex />} />
                    <Route path="bus_managers" element={<BusManagerIndex />} />
                    <Route path="drivers" element={<DriversIndex />} />
                    <Route path="users/add" element={<AddUser />} />
                    <Route path="users/edit/:userId" element={<EditUser />} />
                    <Route path="users/updateUserPassword/:userId" element={<UpdateUserPassword />} />

                    <Route path="rapports/dailybilletterie" element={<RapportDailyBilletterie />} />
                    <Route path="rapports/dailybilletterieByCity/:city" element={<RapportDailyBilletterieByCity />} />
                    <Route path="rapports/dailySumary" element={<DailySumary />} />
                    <Route path="rapports/dailySumaryByCity/:city" element={<DailySumaryByCity />} />
                    <Route path="rapports/billetterie" element={<RapportBilletterie />} />
                    <Route path="rapports/billetterieByCity/:city" element={<RapportBilletterieByCity />} />


                    <Route path="rapports/dailyfrets" element={<RapportDailyFret />} />
                    <Route path="rapports/dailyfretsByCity/:city" element={<RapportDailyFretByCity />} />
                    <Route path="rapports/fretsByCity/:city" element={<RapportFretByCity />} />
                    <Route path="rapports/weeklyFretsByCity/:city" element={<RapportWeeklyFretByCity />} />
                    <Route path="rapports/frets" element={<RapportFret />} />
                    <Route path="rapports/weeklyFrets" element={<RapportWeeklyFret />} />


                    <Route path="rapports/dailyBusSpents" element={<DailyAdminBusSpents />} />
                    <Route path="rapports/dailyBusSpentsByCity/:city" element={<DailyAdminBusSpentsByCity />} />

                    <Route path="rapports/allBusSpentsByCity/:city" element={<AllAdminBusSpentsByCity />} />
                    <Route path="rapports/allBusSpents" element={<AllAdminBusSpents />} />
                    <Route path="rapports/weeklyBusSpents" element={<WeeklyAdminBusSpents />} />
                    <Route path="rapports/weeklyBusSpentsByCity/:city" element={<WeeklyAdminBusSpentsByCity />} />


                    <Route path="rapports/allSpents" element={<AllAdminSpents />} />
                    <Route path="rapports/dailySpents" element={<DailyAdminSpents />} />

                    <Route path="passengers" element={<PassengersIndex />} />

                </Route>

                <Route path="/customer/" element={<CustomerLayout allowedRoles={["ROLE_VENDOR"]} />}>

                    <Route index element={<CustomerDashboard />}/>
                    <Route path="dashboard" element={<CustomerDashboard />}/>

                    <Route path="newVente" element={<NewVente />}/>
                    <Route path="newVenteReservation" element={<NewVenteReservation />}/>
                    <Route path="newVenteTransite" element={<NewVenteTransite />}/>

                    <Route path="dailyVentes" element={<DailyVentes />}/>
                    <Route path="allVentes" element={<AllVentes />}/>
                    <Route path="tickets/printer/:ticketId" element={<PrintComponent />}/>
                    <Route path="tickets/edit/:ticketId" element={<EditTicket />}/>


                    {/*<Route path="newFret" element={<NewFret />}/>*/}
                    {/*<Route path="dailyFrets" element={<DailyFrets />}/>*/}
                    {/*<Route path="allFrets" element={<AllFrets />}/>*/}
                    {/*<Route path="frets/printer/:fretId" element={<ComponentToPrintFret />}/>*/}
                    {/*<Route path="frets/edit/:fretId" element={<EditFret />}/>*/}

                    <Route path="newBusSpent/:busSpentId" element={<NewBusSpent />}/>
                    <Route path="dailyBusSpents" element={<DailyBusSpents />}/>
                    <Route path="allBusSpents" element={<AllBusSpents />}/>


                    <Route path="newSpent" element={<NewSpent />}/>
                    <Route path="dailySpents" element={<DailySpents />}/>
                    <Route path="allSpents" element={<AllSpents />}/>

                    <Route path="share/bus/:assigmentId" element={<ShareBus />}/>

                </Route>

                <Route path="/manifest/" element={<ManifestLayout allowedRoles={["ROLE_MANIFEST"]} />}>

                    <Route index element={<ManifestDashboard />}/>
                    <Route path="dashboard" element={<ManifestDashboard />}/>

                    <Route path="embarquerFret" element={<EmbarquerFret />}/>
                    <Route path="sentFrets" element={<SentFrets />}/>
                    <Route path="receivedFret" element={<ReceivedFret />}/>
                    <Route path="fretByBus" element={<DailyFretsByBus />}/>
                    <Route path="allFretByBus" element={<AllBusManifest />}/>
                    <Route path="fretReceivedByBus" element={<DailyReceivedFrets />}/>
                    <Route path="fretSDelivereds" element={<FretSDelivereds />}/>

                </Route>

                <Route path="/fret/" element={<FretLayout allowedRoles={["ROLE_FRET"]} />}>

                    <Route index element={<FretDashboard />}/>
                    <Route path="dashboard" element={<FretDashboard />}/>

                    <Route path="newFret" element={<NewFret />}/>
                    <Route path="dailyFrets" element={<DailyFrets />}/>
                    <Route path="allFrets" element={<AllFrets />}/>
                    <Route path="frets/printer/:fretId" element={<ComponentToPrintFret />}/>
                    <Route path="frets/edit/:fretId" element={<EditFret />}/>

                </Route>

                <Route path="/manager/" element={<ManagerLayout allowedRoles={["ROLE_MANAGER"]} />}>

                    <Route index element={<ManagerDashboard />}/>
                    {/*<Route path="dashboard" element={<ManagerDashboard />}/>*/}

                    <Route path="buses" element={<ManagerBus />}/>
                    <Route path="buses/add_availablity" element={<AddAvailablity />}/>
                    <Route path="buses/add-Sheduled-availablity" element={<AddSheduledAvailablity />}/>
                    <Route path="buses/availablities" element={<Allavalablities />}/>
                    <Route path="buses/availablity/:available_id/edit" element={<EditAvailablity />}/>

                    <Route path="drivers" element={<ManagerDriversIndex />}/>
                    <Route path="gerants" element={<ManagerBusManagerIndex />}/>

                    <Route path="new_assignment" element={<AddAssigngment />}/>
                    <Route path="new-sheduled-assignment" element={<AddSheduledAssigngment />}/>
                    <Route path="assignment/:assiId/edit" element={<EditAssignment />}/>
                    <Route path="new_transite_assignment" element={<AddTransiteAssigngment />}/>
                    <Route path="new_reservation_assignment" element={<AddReservationAssigngment />}/>
                    <Route path="all_assignments" element={<AllAssignments />}/>

                </Route>
            </Routes>
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} theme="colored"  />
        </HashRouter>
    </>
  );
}

export default App;

// const rootElement = document.querySelector("#app");
// const root = ReactDOM.createRoot(document.querySelector("#app"));
// root.render(<App />);
