import React, { useEffect, useState, useRef } from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Moment from "moment";
import BusesAPI from "../../apis/managerApi/busAPI";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import Select from "../forms/Select";
import TableLoader from "../loaders/TableLoader";
import Pagination from "../assets/Pagination";
import MyButton from "../assets/MyButton";
import Field from "../forms/Field";

const AddTransiteAssigngment = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [transitebuses, seTtransitebuses] = useState([]);
    const [buses, setBuses] = useState([]);
    const [vendeurs, setVendeurs] = useState([]);
    const [dailyAssignments, setDailyAssignments] = useState([]);
    const [trajets, setTrajets] = useState([]);
    const [busesHours, setBusesHours] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");

    const [loading, setLoading] = useState(true);

    const fetchTrajets = async () => {
        try{
            const data =  await BusesAPI.findTrajectsForTransite();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };

    const findDailyTransitAssignments = async () => {
        try{
            const data =  await BusesAPI.findDailyTransitAssignments();
            setDailyAssignments(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des afectations bus de transites !");
        }
    };

    const getPlace = async () => {
        try{
            const data =  await BusesAPI.getPlacesOfDirectAssignementByTransiteCities();
            seTtransitebuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des places !");
        }
    };


    const fetchBusHours = async () => {
        try{
            const data =  await BusesAPI.findBusHours();
            setBusesHours(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des heures de bus !");
        }
    };

    const fetchDirectAssignments = async () => {
        try{
            const data =  await BusesAPI.findDailyDirectAssignments();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement  de bus !");
        }
    };

    const fetchVendeurs = async () => {
        try{
            const data =  await BusesAPI.fetchVendorsForTransite();
            setVendeurs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des vendeurs !");
        }
    };


    useEffect(() => {
        fetchTrajets();
        findDailyTransitAssignments();
        getPlace();
        fetchBusHours();
        fetchDirectAssignments();
        fetchVendeurs();
    },[]);

    const tooltip = (chaffeuer, escorteur) => (
        <Tooltip className="in" id="tooltip">
            <ul><li>Chaf: {chaffeuer}</li><li>Escort: {escorteur}</li></ul>
        </Tooltip>
    );

    const [assignment, setAssignment] = useState({
        bus_id : "", p_id : "", reservation : 2, busHour : "", destination : "", genre : 1
    });

    const [errors, setErrors] = useState({
        bus_id : "", p_id : "", busHour : "", destination : ""
    });


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAssignment({...assignment, [name] : value})
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await BusesAPI.createAssignment(assignment);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Affectations ajouté avec succès");
            window.location.reload();
        }catch({response}){
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de l'ajout de l'affectation !");
            }
        }
    };

    let countir = 1;

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    const filteredDailyAssignments = dailyAssignments.filter(
        b =>
            b.bus_number.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.final_destination.toLowerCase().includes(search.toLowerCase()) ||
            b.from.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedDailyAssignments = Pagination.getData(
        filteredDailyAssignments,
        currentPage,
        itemsPerPage
    );


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <h2 className="card-title">Affecter un bus à un point de vente</h2>

                                <Select name="bus_id" label="Bus disponible" value={assignment.bus_id}
                                        error={errors.bus_id} onChange={handleChange}>
                                    <option value="">Choisir</option>
                                    {transitebuses.length > 0 && buses.map(bus =>
                                        <option key={bus.id} value={bus.id}>
                                            {bus.bus_number} - {bus.bus_numberplate}
                                        </option>)
                                    }
                                </Select>

                                <Select name="p_id" label="Agence ou Point de vente" value={assignment.p_id}
                                        error={errors.p_id} onChange={handleChange}>
                                    <option value="">Choisir</option>
                                    {vendeurs.map(vendeur =>
                                        <option key={vendeur.id} value={vendeur.id}>
                                            {vendeur.name} |
                                            {vendeur.pos_name}</option>)
                                    }
                                </Select>

                                <div className="row">
                                    <Select name="destination" label="Destination" value={assignment.destination}
                                            error={errors.destination} onChange={handleChange}>
                                        <option value="">Choisir</option>
                                        {trajets.map(trajet =>
                                            <option key={trajet.id} value={trajet.id}>{trajet.from} - {trajet.to}</option>)
                                        }
                                    </Select>

                                    {/*<div className="col-md-6">*/}
                                        {/*<Select name="busHour" label="Heure de bus" value={assignment.busHour}*/}
                                                {/*error={errors.busHour} onChange={handleChange}>*/}
                                            {/*<option value="">Choisir</option>*/}
                                            {/*{busesHours.map(busH =>*/}
                                                {/*<option key={busH.id} value={busH.id}>{busH.bus_hour}</option>)*/}
                                            {/*}*/}
                                        {/*</Select>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="row">
                                    <Field
                                        name="busHour"
                                        error={errors.busHour}
                                        value={assignment.busHour}
                                        onChange={handleChange}
                                        label="Heure de bus"
                                        placeholder="Ex: 06h30"
                                        autoComplete="off"
                                    />
                                </div>

                                <MyButton
                                    text="Ajouter"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />
                                <Link to="/manager/buses" className="btn btn-danger mx-2">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-success bold">Bus de transite disponibles et Nombre de place</h3>
                            {!loading && <ol className="list-group list-group-numbered">
                                {transitebuses.map((bus, index) => (
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className=""><b>{bus.bus_number}</b>/{bus.bus_numberplate} |
                                                Vers : <b>{bus.final_destination}</b></div>
                                            </div>
                                            Escale : <b> {bus.place} </b> |
                                            Place(s) : <b> {bus.number} </b>
                                        </li>
                                    ))
                                }
                            </ol>}
                            {loading && <TableLoader width={300} height={350} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Total : <b className="text-primary">{dailyAssignments ? dailyAssignments.length : 0} </b>
                            </h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                    </div>
                                </div>
                            </div>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Depart</th>
                                    <th scope="col">Agence</th>
                                    <th scope="col">Provenance</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Heure</th>
                                    <th scope="col">Status</th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {paginatedDailyAssignments.map((assignment) => (
                                        <tr key={assignment.id}>
                                            <th scope="row">{countir++}</th>
                                            <td>
                                                <b>{assignment.bus_number} - {assignment.bus_numberplate}, S: {assignment.bus_number_of_seats}</b>
                                            </td>
                                            <td>{assignment.bus_hour}</td>
                                            <td>{assignment.p_name}</td>
                                            <td>{assignment.from}</td>
                                            <td>{assignment.to}</td>
                                            <td>{Moment(assignment.created_at).format("HH:mm")}</td>
                                            <td>
                                                <ButtonToolbar>
                                                    <OverlayTrigger placement="top"
                                                                    overlay={tooltip(assignment.driver_name,assignment.manager_name)}>
                                                        <Button className="badge btn btn-dark">
                                                            <i className="bi bi-info-circle"></i></Button>
                                                    </OverlayTrigger>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>
                                    ))
                                }

                                </tbody>}
                            </table>

                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredDailyAssignments.length &&
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredDailyAssignments.length}
                                onPageChange={handleChangeCurrentPage}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTransiteAssigngment;
