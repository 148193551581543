import React, { useEffect, useState } from 'react';
import {toast} from "react-toastify";
import Pagination from "../../assets/Pagination";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import TableLoader from "../../loaders/TableLoader";
import Moment from "moment/moment";
import CustomAPI from "../../../apis/fretAgentApi/FretAPI";

const DailyVentes = () => {

    const [total, setTotal] = useState("");
    const [dailyTickets, setDailyTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSubPage, setCurrentSubPage] = useState(1);
    const [search, setSearch] = useState("");
    const [searchSub, setSubSearch] = useState("");
    const [trajs, setTrajets] = useState([]);
    const [setting, setSetting] = useState([]);

    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");
    const [dailySpents, setDailyBusSpents] = useState([]);

    const getAllDailySpents = async () => {
        try{
            const data =  await VendeurAPI.dailySpents();
            setDailyBusSpents(data.spents);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const getAllTickets = async () => {
        try{
            const data =  await VendeurAPI.dailySales();
            setDailyTickets(data.buses);
            setOutPutData(data.buses);
            setTotal(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const fetchSetting = async () => {
        try{
            const data =  await CustomAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const getTrajets = async () => {
        try{
            const data =  await VendeurAPI.getTrajets();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };

    useEffect(() => {
        getAllTickets();
        getTrajets();
        getAllDailySpents();
        fetchSetting();
    },[]);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche
    const filteredTickets = vivo.filter(

        b =>
        b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
        b.to.toLowerCase().includes(search.toLowerCase()) ||
        //b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
        b.from.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedTickets = Pagination.getData(
        filteredTickets,
        currentPage,
        itemsPerPage
    );


    let counter = 1;


    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = dailyTickets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = dailyTickets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = dailyTickets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };

    function calculTotalFret(data) {
        let tCDF = 0;
        data.map((toto) => toto.fret_items.map((f) => tCDF += f.amount));
        return tCDF
    }

    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.amount);
        return tCDF
    }

    function calculCDF(data) {
        let tCDF = 0;
        data.map((item) => tCDF += item.totalCDF);
        return tCDF
    }

    function calculUSD(data) {
        let tUSD = 0;
        data.map((item) => tUSD += item.totalUSD);
        return tUSD
    }

    function calculTotal(data) {
        let tCDF = 0;
        data.map((toto) => toto.tickets.map((ticket) => tCDF += ticket.amount));
        return tCDF
    }

    //console.log(paginatedTickets);

    function calculTotalDepenseCDF(data) {
        let tCDF = 0;
        data.map((toto) => toto.bus_spents.map((item) => tCDF += item.totalCDF));
        return tCDF
    }

    function calculTotalDepenseUSD(data) {
        let tUSD = 0;
        data.map((toto) => toto.bus_spents.map((item) => tUSD += item.totalUSD));
        return tUSD
    }

    const ticketsByDestination = (data) => {
        const destinations = data.tickets.map((key, value) => key = key.destination);
        let data_table = [];
        destinations.forEach((val) => {
            if (data_table[val]) {
                data_table[val] += 1;
            } else {
                data_table[val] = 1;
            }
        });
        return data_table;
    };


    function calculTotalDailySpents(data) {
        let tUSD = 0;
        let tCDF = 0;
        data.map((spent) => tCDF += spent.totalCDF);
        data.map((spent) => tUSD += spent.totalUSD);
        return {tCDF, tUSD}
    }



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Total bus : <b className="text-primary">{paginatedTickets ? paginatedTickets.length : 0} </b> |
                                {total && (<><b className="text-primary">{calculTotal(paginatedTickets)}</b> Fc</>)} |
                                {/*Fret : {total && (<><b className="text-success">{calculTotalFret(paginatedTickets)}</b> CDF</>)}*/}
                                <br/>
                                Total Depense bus CDF : {(<><b className="text-danger">{calculTotalDepenseCDF(paginatedTickets)}</b> Fc</>)} |
                                Total Depense bus USD : {(<><b className="text-danger">{calculTotalDepenseUSD(paginatedTickets)}</b> $</>)} |
                                <br/>
                                Benefice du jour <small>{calculTotal(paginatedTickets)} - {(calculTotalDepenseCDF(paginatedTickets) + (calculTotalDepenseUSD(paginatedTickets) * setting.currency)) +
                            calculTotalDailySpents(dailySpents).tCDF + (calculTotalDailySpents(dailySpents).tUSD * setting.currency)
                            }</small> = {(<><b className="text-success">
                                {calculTotal(paginatedTickets) - ((calculTotalDepenseCDF(paginatedTickets) + (calculTotalDepenseUSD(paginatedTickets)
                                    * setting.currency)) + calculTotalDailySpents(dailySpents).tCDF +
                                    (calculTotalDailySpents(dailySpents).tUSD * setting.currency))}</b> Fc</>)}
                            </h2>


                            <div className="row mb-3">
                                <div className="col-md-5">
                                    <div className="form-group mt-2">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par Bus
                                        </label>
                                        <input type="text"
                                               onChange={handleSearch}
                                               value={search}
                                               name="ByAll"
                                               id="ByAll"
                                               className="form-control"
                                               placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>

                            </div>
                            {!loading && <ol className="list-group list-group-numbered">
                                {paginatedTickets.map((bus) => (
                                    <div key={bus.id}>
                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold"><span className="text-success">{bus.bus_numberplate}</span></div>
                                                Destination : <b>{bus.to}</b>, Depart : {bus.bus_hour}, <br/>Chauffeur : <b>{bus.driver_name}</b>,
                                                Escorteur : <b>{bus.manager_name}</b><br/>
                                                <span>
                                                    <b className="text-danger">Dépense : {calculCDF(bus.bus_spents)} Fc + {calculUSD(bus.bus_spents)} $</b> =
                                                    <b className="text-success"> {calculSubTotal(bus.tickets) - (calculCDF(bus.bus_spents) + ((calculUSD(bus.bus_spents) * setting.currency)))} Fc</b>
                                                </span>
                                                <br/>
                                                <small><b className="text-danger">Le {Moment(bus.created_at).format("DD/MM/YYYY")}</b>,
                                                    Vendeur {bus.vendor_name}
                                                </small>
                                                <div className="fw-bold">
                                                    {ticketsByDestination(bus).Lubumbashi && (<span className="badge bg-primary mx-1">
                                                Lubumbashi : {ticketsByDestination(bus).Lubumbashi}</span>)}

                                                    {ticketsByDestination(bus).Kolwezi && (<span className="badge bg-success mx-1">
                                                Kolwezi : {ticketsByDestination(bus).Kolwezi}</span>)}

                                                    {ticketsByDestination(bus).Likasi && (<span className="badge bg-info mx-1">
                                                Likasi : {ticketsByDestination(bus).Likasi}</span>)}

                                                    {ticketsByDestination(bus).Fungurume && (<span className="badge bg-dark mx-1">
                                                Fungurume : {ticketsByDestination(bus).Fungurume}</span>)}

                                                    {ticketsByDestination(bus).Kasumbalesa && (<span className="badge bg-danger mx-1">
                                                Kasumbalesa : {ticketsByDestination(bus).Kasumbalesa}</span>)}

                                                    {ticketsByDestination(bus).Sakania && (<span className="badge bg-primary mx-1">
                                                Sakania : {ticketsByDestination(bus).Sakania}</span>)}
                                                </div>
                                            </div>

                                            <span className="badge bg-primary rounded-pill mx-2">{bus.tickets.length} tickets / {bus.bus_number_of_seats}</span>
                                            <a className="badge bg-success" data-bs-toggle="collapse"
                                               href={"#tickets"+bus.id} role="button" aria-expanded="false"
                                               aria-controls={"tickets"+bus.id}>Voir <i className="bi bi-eye"></i> </a>
                                        </li>
                                        <div className="collapse multi-collapse" id={"tickets"+bus.id}>
                                            <div className="card card-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h4 className="text-primary">Tickets</h4>
                                                        <table className="table table-hover table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">Numero</th>
                                                                <th scope="col">Client</th>
                                                                <th scope="col">Destination</th>
                                                                <th scope="col">Montant</th>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Bonus</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {bus.tickets.map((ticket) => (
                                                                <tr key={ticket.id}>
                                                                    <td>{ticket.id}</td>
                                                                    <td><b>{ticket.customer}</b></td>
                                                                    <td>{ticket.destination}</td>
                                                                    <td>{ticket.amount} CDF</td>
                                                                    <td>{
                                                                        ticket.genre === 0 ? (<b className="badge text-bg-success">Direct</b>)
                                                                            :(<b className="badge text-bg-primary">Transite</b>)
                                                                    }</td>
                                                                    <td>{ticket.bonus === 1 ? (<b className="badge text-bg-success">Oui</b>)
                                                                        :(<b className="badge text-bg-dark">Non</b>)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </ol>}

                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredTickets.length &&
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredTickets.length}
                                onPageChange={handleChangeCurrentPage}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyVentes;
