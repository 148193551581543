//const apiUrl = "https://www.api.kksenterprise.com";
const apiUrl = "http://127.0.0.1:8000";

function ajax(url, requestMethod, jwt, requestBody) {
    const fetchData = {
        headers: {
            "Content-Type": "application/json",
        },
        method: requestMethod,
    };

    if (jwt) {
        fetchData.headers.Authorization = `Bearer ${jwt}`;
    }

    if (requestBody) {
        fetchData.body = JSON.stringify(requestBody);
    }

    return fetch(apiUrl+url, fetchData).then((response) => {
        if (response.status === 200) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        }else{
            return response.json();
        }
    });
}

export default ajax;