import React,{useState} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../../hooks/usesAuth";
import AuthAPI from "../../../Services/auth/authAPI";
import Manifest_header from "../headers/Manifest_header";
import ManifestSidebar from "../sidebars/ManifestSidebar";

export const ManifestLayout = ({ allowedRoles }) => {

    const { auth } = useAuth();
    const location = useLocation();


    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <Manifest_header />
            <ManifestSidebar />
            <main id="main" className="main">
                <Outlet />
            </main>

        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default ManifestLayout;
