import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Moment from "moment/moment";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import TrajetsAPI from "../../../apis/adminApi/trajetsAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";

const TrajetIndex = () => {

    const [trajets, setTrajet] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchTrajets = async () => {
        try{
            const data =  await TrajetsAPI.findAll();
            setTrajet(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };



    //Au chargement du composant on va chercher les trajets
    useEffect(() => {
        fetchTrajets();
    }, []);

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des trajets en fonction de la recherche

    const filteredTrajets = trajets.filter(
        t =>
            t.from.toLowerCase().includes(search.toLowerCase()) ||
            t.to.toLowerCase().includes(search.toLowerCase()) ||
            t.price.toString().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedTrajets = Pagination.getData(
        filteredTrajets,
        currentPage,
        itemsPerPage
    );

    let counter = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les trajets</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les Trajets de route KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {trajets ? trajets.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3" to="/admin/trajets/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter un trajet
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Provenance</th>
                                        <th scope="col">Destination</th>
                                        <th scope="col">Prix Transport</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedTrajets.map((trajet) => (
                                        <tr key={trajet.id}>
                                            <th scope="row">{counter++}</th>
                                            <td>{trajet.from}</td>
                                            <td>{trajet.to}</td>
                                            <td>{trajet.price} CDF</td>
                                            <td>
                                                <Link className="btn btn-sm btn-success mx-1" to={`/admin/trajets/edit/${trajet.id}`}>
                                                    <i className="bi bi-pen-fill"></i>
                                                </Link>
                                                {/*<Link className="btn btn-sm btn-danger">*/}
                                                    {/*<i className="bi bi-basket"></i>*/}
                                                {/*</Link>*/}
                                            </td>
                                        </tr>))}
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredTrajets.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredTrajets.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
};

export default TrajetIndex;
