import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';

import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import PvsAPI from "../../../apis/adminApi/posAPI";
import BusesAPI from "../../../apis/adminApi/posAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import AgencyAPI from "../../../apis/adminApi/agencyAPI";
import Select from "../../forms/Select";

const EditTrajet = () => {
    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    const [agencies, setAgencies] = useState([]);
    let {pvId} = useParams();

    const [showLoader, setShowLoader] = useState(false);

    const [pv, setPv] = useState({
        pos_name : "", pos_city : "", pos_address : "", id : pvId, agency_id:""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        pos_name : "", pos_city : "", pos_address : "", agency_id:""
    });


    //Recuperation
    const fetchPv = async id => {
        try{
            const { pos_name, pos_city, pos_address, agency_id } = await PvsAPI.find(id);
            setPv({ pos_name, pos_city, pos_address, agency_id });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le point de vente démandé !");
            navigate("/admin/pvs");
        }
    };

    const fetchAgencies = async () => {
        try{
            const data =  await AgencyAPI.findAll();
            setAgencies(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des agencies !");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchPv(pvId);
        fetchAgencies();
    }, [pvId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setPv({...pv, [name] : value})
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await PvsAPI.updatePv(pvId, pv);
            toast.success("Point de vente modifié avec succès");
            setTimeout(() => setShowLoader(false), 1000);
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du pv !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un pv</h5>

                                        <Field
                                            name="pos_name"
                                            error={errors.pos_name}
                                            value={pv.pos_name}
                                            onChange={handleChange}
                                            label="Nom du Point de vente"
                                        />

                                        <Field
                                            name="pos_city"
                                            error={errors.pos_city}
                                            value={pv.pos_city}
                                            onChange={handleChange}
                                            label="Ville du Point de vente"
                                        />

                                        <Field
                                            name="pos_address"
                                            error={errors.pos_address}
                                            value={pv.pos_address}
                                            onChange={handleChange}
                                            label="Adresse du Point de vente"
                                        />


                                        <Select name="agency_id" label="Agence" value={pv.agency_id} error={errors.agency_id} onChange={handleChange}>
                                            <option value="">Choisir</option>
                                            {agencies.map(agency =>
                                                <option key={agency.id} value={agency.id}>
                                                    {agency.agency_name} - {agency.agency_city}
                                                </option>)
                                            }
                                        </Select>



                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/pvs" className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditTrajet;
