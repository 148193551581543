import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import BusesAPI from "../../apis/managerApi/busAPI";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";

const AllAssignments = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [assignments, setAssigments] = useState([]);

    let counter = 1;
    let countir = 1;

    const fetchAssignments = async () => {
        try{
            const data =  await BusesAPI.findAssignments();
            setAssigments(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des affectations !");
        }
    };

    useEffect(() => {
        fetchAssignments()
    },[]);


    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;


    const filteredAssignments = assignments.filter(
        b =>
            b.p_name.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.driver_name.toLowerCase().includes(search.toLowerCase()) ||
            b.manager_name.toLowerCase().includes(search.toLowerCase()) ||
            b.name.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase())
    );

    const paginatedAssignments = Pagination.getData(
        filteredAssignments,
        currentPage,
        itemsPerPage
    );


    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );


    return (
        <>
            <div className="pagetitle">
                <h1>Les buses</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les disponibilités des bus</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-5">
                                        <h5 className="card-title">Total {assignments ? assignments.length : 0}</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Agence</th>
                                        <th scope="col">Chauffeur</th>
                                        <th scope="col">Escorteur</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Gestionnaire</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedAssignments.map((assignment) => (
                                            <tr key={assignment.id}>
                                                <th scope="row">{countir++}</th>
                                                <td>
                                                    <b>{assignment.bus_numberplate}, S: {assignment.bus_number_of_seats}</b>
                                                </td>
                                                <td>{assignment.p_name}</td>
                                                <td>{assignment.driver_name}</td>
                                                <td>{assignment.manager_name}</td>
                                                <td>Le {Moment(assignment.created_at).format("DD-MM-YYYY, HH:mm")}</td>
                                                <td>{assignment.name}</td>
                                                <td>
                                                    <ButtonToolbar>
                                                        <OverlayTrigger placement="top"
                                                                        overlay={tooltip("test")}>
                                                            <Button className="badge btn btn-dark">
                                                                <i className="bi bi-info-circle"></i></Button>
                                                        </OverlayTrigger>
                                                    </ButtonToolbar>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredAssignments.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredAssignments.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllAssignments;
