function TableRows({rowsData, deleteTableRows, handleChange}) {


    return(

        rowsData.map((data, index)=>{
            const {currency, amount, title} = data;
            return(

                <tr key={index}>

                    <td>
                        <input
                            placeholder="Description"
                            type="text"
                            value={title}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="title"
                            className="form-control"/>
                    </td>

                    <td>
                        <input
                            placeholder="00"
                            type="number"
                            value={amount}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="amount"
                            className="form-control"/>
                    </td>

                    <td>
                        <select
                            className="form-control form-select"
                            name="currency"
                            value={currency}
                            onChange={(evnt)=>(handleChange(index, evnt))}
                            aria-label="Nom du colis">
                            <option value="">Choisir</option>
                            <option value="CDF">CDF</option>
                            <option value="USD">USD</option>
                        </select>
                    </td>


                    <td><a className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>
                        <i className="bi bi-trash-fill"></i>
                    </a></td>
                </tr>

            )
        })

    )

}

export default TableRows;