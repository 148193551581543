import React from 'react';


const CheckBoxField = ({name, label, value, onChange, checked}) =>
(<div className="mb-3 form-check">
    <input 
        value={value} 
        onChange={onChange}
        name={name} 
        type="checkbox"
        className="form-check-input"
        id={name}
        checked={checked}
    />
    <label className="form-check-label"  htmlFor={name}>{label}</label>
</div> 
);
 
export default CheckBoxField;