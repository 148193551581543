import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ajax from "../../../Services/fetchService";
import {useUser} from "../../../Services/userProvider";
import Moment from "moment/moment";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import BusesAPI from "../../../apis/adminApi/busAPI";
import TableLoader from "../../loaders/TableLoader";
import Pagination from "../../assets/Pagination";

const BusIndex = () => {

    const user = useUser();
    const [buses, setBuses] = useState([]);
    const [dailyavailablities, setdailyavailablities] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchBuses = async () => {
        try{
            const data =  await BusesAPI.findAll();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des buses !");
        }
    };

    const fetchBusesAvailabilities = async () => {
        try{
            const data =  await BusesAPI.findAll();
            setdailyavailablities(data.dailyavailablities);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des buses !");
        }
    };


    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchBuses();
        fetchBusesAvailabilities();
    }, []);

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredBuses = buses.filter(
        b =>
            b.bus_code.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_seat_number.toString().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedBuses = Pagination.getData(
        filteredBuses,
        currentPage,
        itemsPerPage
    );

    let numerotation = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les buses</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les bus KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {buses ? buses.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3 d-block" to="/admin/buses/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter un bus
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Numéro</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Nbre de siège</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedBuses.map((bus) => (
                                            <tr key={bus.id}>
                                                <th scope="row">{bus.id}</th>
                                                <td>{bus.bus_code}</td>
                                                <td>{bus.bus_numberplate}</td>
                                                <td className="text-center">{bus.bus_seat_number}</td>
                                                <td>
                                                    <Link className="btn btn-sm btn-outline-primary mx-1" to={`/admin/buses/edit/${bus.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>
                                                    {/*<Link className="btn btn-sm btn-danger">*/}
                                                        {/*<i className="bi bi-basket"></i>*/}
                                                    {/*</Link>*/}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>}
                                </table>
                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredBuses.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredBuses.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title">
                                    Aujourd'hui :
                                    <b className="text-primary mx-1">
                                         {dailyavailablities ? dailyavailablities.length : 0}
                                    </b>
                                </h2>
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Heure</th>
                                        <th scope="col">Ville</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {
                                        dailyavailablities ? (dailyavailablities.map((dispo) => (
                                            <tr key={dispo.id}>
                                                <th scope="row">{numerotation++}</th>
                                                <td>{dispo.bus_code}</td>
                                                <td>{dispo.bus_numberplate}</td>
                                                <td>{Moment(dispo.created_at).format("HH:mm")}</td>
                                                <td>{dispo.city}</td>
                                                <td>
                                                    {dispo.available === 1  ?(<span className="text-success"><i className="bi bi-check-all"></i></span>)
                                                        :(<ButtonToolbar>
                                                            <OverlayTrigger placement="top" overlay={tooltip(dispo.pattern)}>
                                                                <Button className="badge btn btn-danger">Indispo</Button>
                                                            </OverlayTrigger>
                                                        </ButtonToolbar>)
                                                    }
                                                </td>
                                            </tr>
                                        ))) : (<></>)
                                    }

                                    </tbody>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BusIndex;
