import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const AdminSidebar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };


    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/admin' ? 'nav-link ' : 'nav-link collapsed'} to="/admin">
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>

                <li className="nav-heading">UTILISATEURS</li>


                <li className="nav-item">
                    <a className={pathname === '/admin/vendeurs' || pathname === '/admin/comptables' || pathname ==='/admin/users/add'
                    || pathname ==='/admin/users/edit/'+id.userId
                        ? 'nav-link ' : 'nav-link collapsed'}
                       data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-person"></i><span>Utilisateurs</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="icons-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/admin/managers">
                                <i className="bi bi-person"></i><span>Gestionnare bus</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/vendeurs">
                                <i className="bi bi-person"></i><span>Vendeurs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/fretOperateurs">
                                <i className="bi bi-person"></i><span>Agents Frets</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/manifests">
                                <i className="bi bi-person"></i><span>Agents Manifestes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/drivers">
                                <i className="bi bi-person"></i><span>Chauffeurs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/bus_managers">
                                <i className="bi bi-person"></i><span>Escorteurs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/admins">
                                <i className="bi bi-person"></i><span>Admins</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/passengers">
                                <i className="bi bi-person"></i><span>Les clients</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="nav-heading">Bus</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/buses' || pathname ==='/admin/buses/add'
                    || pathname ==='/admin/buses/edit/'+id.busId
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/buses">
                        <i className="bi bi-bus-front"></i>
                        <span>Les bus</span>
                    </Link>
                </li>

                <li className="nav-heading">Transport</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/trajets' || pathname ==='/admin/trajets/add'
                    || pathname ==='/admin/trajets/edit/'+id.trajetId
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/trajets">
                        <i className="bi bi-diagram-3"></i>
                        <span>Les trajets</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/agencies' || pathname ==='/admin/agencies/add'
                    || pathname ==='/admin/agencies/edit/'+id.pvId
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/agencies">
                        <i className="bi bi-shop-window"></i>
                        <span>Les Agences</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/pvs' || pathname ==='/admin/pvs/add'
                    || pathname ==='/admin/pvs/edit/'+id.pvId
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/pvs">
                        <i className="bi bi-shop"></i>
                        <span>Points de vente</span>
                    </Link>
                </li>


                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/admin/busesHours' || pathname ==='/admin/busesHours/add'*/}
                    {/*|| pathname ==='/admin/busesHours/edit/'+id.busHourId*/}
                        {/*? 'nav-link ' : 'nav-link collapsed'} to="/admin/busesHours">*/}
                        {/*<i className="bi bi-play-fill"></i>*/}
                        {/*<span>Les Heures de bus</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                <li className="nav-heading">Rapports de Billeterie</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/dailybilletterie'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailybilletterie">
                        <i className="bi bi-clipboard"></i>
                        <span>Rapport du jour</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/dailySumary'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailySumary">
                        <i className="bi bi-clipboard-check-fill"></i>
                        <span>BUS 7 DERNIERS JOURS </span>
                    </Link>
                </li>


                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/billetterie'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/billetterie">
                        <i className="bi bi-clipboard-fill"></i>
                        <span>RAPPORT GENERAL</span>
                    </Link>
                </li>

                <li className="nav-heading">Rapports de Fret</li>


                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/dailyfrets'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailyfrets">
                        <i className="bi bi-inboxes"></i>
                        <span>Rapport fret du jour</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/frets'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/weeklyFrets">
                        <i className="bi bi-inboxes-fill"></i>
                        <span>Frets 7 DERNIERS JOURS</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/frets'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/frets">
                        <i className="bi bi-boxes"></i>
                        <span>Tous les Frets</span>
                    </Link>
                </li>

                <li className="nav-heading">Rapport de depnses de bus</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/dailyBusSpents'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailyBusSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Aujourd'hui</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/allBusSpents'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/weeklyBusSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Depenses 7 DERNIERS JOURS</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/allBusSpents'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/allBusSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Toutes les depenses de bus</span>
                    </Link>
                </li>


                <li className="nav-heading">Rapport autres depnses</li>

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/admin/rapports/dailySpents'*/}
                        {/*? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/dailySpents">*/}
                        {/*<i className="bi bi-play-fill"></i>*/}
                        {/*<span>Aujourd'hui</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                <li className="nav-item">
                    <Link className={pathname === '/admin/rapports/allSpents'
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/rapports/allSpents">
                        <i className="bi bi-play-fill"></i>
                        <span>Toutes les autres depenses</span>
                    </Link>
                </li>

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/admin/fret_catalogues'*/}
                        {/*? 'nav-link ' : 'nav-link collapsed'} to="/admin/fret_catalogues">*/}
                        {/*<i className="bi bi-play-fill"></i>*/}
                        {/*<span>Catalogue des prix</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                <li className="nav-heading">Paramettres</li>

                <li className="nav-item">
                    <Link className={pathname === '/admin/settings' || pathname ==='/admin/settings/add'
                    || pathname ==='/admin/settings/edit/'+id.settingId
                        ? 'nav-link ' : 'nav-link collapsed'} to="/admin/settings">
                        <i className="bi bi-gear"></i>
                        <span>Paramettres</span>
                    </Link>
                </li>

            </ul>

            <div className="fixed-bottom mx-4 my-3">
                <button className="btn btn-danger" onClick={handleLogout}>
                    <i className="bi bi-box-arrow-left"></i>
                    <span> Se deconnecter</span>
                </button>
            </div>



        </aside>
    );
};

export default AdminSidebar;
