import React,{useState,useEffect,useRef} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import {faCheck, faTimes, faInfoCircle,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useUser} from "../../../Services/userProvider";

const USER_REGEX = /^[A-z][A-z0-9-_]{4,23}$/;
const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PHONE_REGEX = /^[+][0-9-_]{12}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const RegisterPage = () => {
    const userRef = useRef();
    const errRef = useRef();
    const user = useUser();

    const [username, setUsername] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);
    const [validUsername, setValidUsername] = useState(false);
    const [usernameFocus, setUsernameFocus] = useState(false);

    const [role, setRole] = useState("");
    const [validRole, setValidRole] = useState(false);
    const [roleFocus, setRoleFcus] = useState(false);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phoneNumber, setphoneNumbere] = useState("");
    const [validphoneNumber, setValidphoneNumber] = useState(false);
    const [phoneNumberFocus, setphoneNumberFocus] = useState(false);

    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [password_confirm, setPassword_confirm] = useState("");
    const [validPassword_confirm, setValidPassword_confirm] = useState(false);
    const [password_confirmFocus, setPassword_confirmFocus] = useState(false);

    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleChangeCheckBox = () => {
        setIsChecked(!isChecked);
    };

    //Button loading
    const {handleSubmit, formState } = useForm();
    const {isSubmitting} = formState;

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidphoneNumber(PHONE_REGEX.test(phoneNumber));
    }, [phoneNumber]);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email]);

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        const match = password === password_confirm;
        setValidPassword_confirm(match);
    }, [password, password_confirm]);

    useEffect(() => {
        setErrMsg("");
        setErrorMsg("");
    }, [username, phoneNumber, email, password, password_confirm, role,isChecked]);


    const navigate = useNavigate();


    const submitForm = async (event) => {

        //event.preventDefault();
        const data = new FormData(event.currentTarget);
        const sendingData = {
            username: username,
            role: role,
            phoneNumber: phoneNumber,
            email: email,
            password: password,
            password_confirm: password_confirm,
            type: role,
            tc: isChecked,
        };

        //console.log(sendingData.tc);

        if (sendingData.username && sendingData.phoneNumber &&
            sendingData.email && sendingData.password) {
            if (sendingData.password !== sendingData.password_confirm) {
                setErrorMsg("Les mots de passe ne sont pas identiques");
            } else if (sendingData.type === "null") {
                setErrorMsg("Choisir un type de compte");
            } else if (sendingData.tc === false) {
                setErrorMsg("Accepter les termes et conditions");
            } else {
                document.getElementById("register-form").reset();
                const requestBody = {
                    email : email,
                    password : password,
                    phoneNumber: phoneNumber,
                    username : username,
                    role: role
                };

                fetch("/api/v1/auth/register",{
                    headers : {
                        "Content-Type" : "application/json"
                    },
                    method : "POST",
                    body : JSON.stringify(requestBody)
                }).then((res) => res.json()).then((data) => {
                    if (data.httpStatus === 200) {
                        toast.success(data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate(`/validate-account/${data.data.username}`)
                    } else if (data.httpStatus === 400){
                        toast.error(data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((err) => {
                    console.log(err.response);
                })

            }
        } else {
            return new Promise((resolve => {
                setTimeout(() => {
                    resolve();
                    //setErrorMsg("Tous les champs sont requis");
                    toast.error("Tous les champs sont requis", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }, 4000)
            }));
        }
    };



    return (
        <main>
            <div className="container">

                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <Link to="/" className="logo d-flex align-items-center w-auto">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt=""/>
                                            <span className="d-none d-lg-block">KKS EXPRESS</span>
                                    </Link>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Création de compte</h5>
                                            <p className="text-center small">Entrez vos données personnelles pour créer
                                                Compte</p>
                                        </div>

                                        <form id="register-form" className="row g-3 needs-validation" onSubmit={handleSubmit(submitForm)}>
                                            <div className="col-6">

                                                <label htmlFor="username" className="form-label m-0">
                                                    Nom d'utilisateur{" "}
                                                    <FontAwesomeIcon icon={faCheck} className={validUsername ? "text-success p-1" : "d-none"} />
                                                    <FontAwesomeIcon icon={faTimes} className={validUsername || !username ? "d-none" : "p-1 text-danger"} />
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-person"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="username"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                        className="form-control"
                                                        placeholder="Utilisateur"
                                                        autoComplete="off"
                                                        ref={userRef}
                                                        aria-invalid={validUsername ? "false" : "true"}
                                                        aria-describedby="uidnote"
                                                        onFocus={() => setUsernameFocus(true)}
                                                        onBlur={() => setUsernameFocus(false)}
                                                    />
                                                </div>
                                                    <div id="cnidnote" className={usernameFocus && username && !validUsername ? "mt-2" : "d-none"}>
                                                        <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                            <i className="bi bi-info-circle"></i> 5 à 23 charactères;
                                                            Contient des lettres et de chiffres;
                                                            Pas des caractères spéciaux ni d'espace
                                                        </p>
                                                    </div>
                                            </div>

                                            <div className="col-6">
                                                <label htmlFor="phoneNumber">
                                                    Téléphone{" "}<FontAwesomeIcon icon={faCheck} className={validphoneNumber ? "text-success p-1" : "d-none"} />
                                                    <FontAwesomeIcon icon={faTimes} className={validphoneNumber || !phoneNumber ? "d-none" : "p-1 text-danger"} />
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-phone"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        id="phoneNumber"
                                                        placeholder="+243000000000"
                                                        onChange={(e) => setphoneNumbere(e.target.value)}
                                                        aria-invalid={validphoneNumber ? "false" : "true"}
                                                        aria-describedby="phoneidnote"
                                                        onFocus={() => setphoneNumberFocus(true)}
                                                        onBlur={() => setphoneNumberFocus(false)}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div id="phoneidnote" className={phoneNumberFocus && phoneNumber && !validphoneNumber ? "mt-2" : "d-none"}>
                                                    <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                        <i className="bi bi-info-circle"></i> Doit commencer par le symbole +;
                                                        Contient que des chiffres ;
                                                        Pas des caractères spéciaux ni d'espace
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <label htmlFor="email">
                                                    E-mail{" "}<FontAwesomeIcon icon={faCheck} className={validEmail ? "text-success p-1" : "d-none"}/>
                                                    <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "d-none" : "text-danger p-1"}/>
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-envelope"></i>
                                                    </span>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        value={email}
                                                        id="email"
                                                        placeholder="nom@exmaple.com"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        aria-invalid={validEmail ? "false" : "true"}
                                                        aria-describedby="emailidnote"
                                                        onFocus={() => setEmailFocus(true)}
                                                        onBlur={() => setEmailFocus(false)}/>
                                                </div>

                                                <div id="emailidnote" className={emailFocus && email && !validEmail ? "mt-2" : "d-none"}>
                                                    <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                        <i className="bi bi-info-circle"></i> L'email n'est pas une adresse mail valide
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <label htmlFor="role">Type de compte</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-person-exclamation"></i>
                                                    </span>
                                                    <select
                                                        className="form-control form-select"
                                                        name="role"
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                        onBlur={(e) => setRole(e.target.value)}
                                                        aria-label="Type de compte">

                                                        <option value="null">Type de compte</option>
                                                        <option value="customer">Client</option>
                                                        <option value="mercenary">Mercenaire</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <label htmlFor="password">
                                                    Mot de passe{" "} <FontAwesomeIcon icon={faCheck} className={validPassword ? "text-success p-1" : "d-none"} />
                                                    <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "d-none" : "text-danger p-1"}/>
                                                </label>
                                                <div className="input-group">
                                                     <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-lock"></i>
                                                    </span>
                                                    <input
                                                        className="form-control"
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        value={password}
                                                        placeholder="Mot de passe"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        aria-invalid={validPassword ? "false" : "true"}
                                                        aria-describedby="pwdidnote"
                                                        onFocus={() => setPasswordFocus(true)}
                                                        onBlur={() => setPasswordFocus(false)}
                                                    />
                                                </div>
                                                <div id="pwdidnote" className={passwordFocus && password && !validPassword ? "mt-2" : "d-none"}>
                                                    <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                        <i className="bi bi-info-circle"></i> 8 à 24 charactères.
                                                        <br /> Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial
                                                        <br />Caractères spéciaux autorisés :{" "}
                                                        <span aria-label="exclamation mark">!</span>{" "}
                                                        <span aria-label="at symbol">@</span>{" "}
                                                        <span aria-label="hashtag">#</span>{" "}
                                                        <span aria-label="dollar sign">$</span>{" "}
                                                        <span aria-label="percent">%</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="password_confirm">
                                                    Confirmer le mot de passe{" "}<FontAwesomeIcon icon={faCheck} className={validPassword_confirm && password_confirm
                                                    ? "text-success p-1" : "d-none"}/>
                                                    <FontAwesomeIcon icon={faTimes} className={validPassword_confirm || !password_confirm ? "d-none" : "text-danger p-1"}/>
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-lock"></i>
                                                    </span>
                                                    <input
                                                        className="form-control"
                                                        id="password_confirm"
                                                        type="password"
                                                        value={password_confirm}
                                                        name="password_confirm"
                                                        placeholder="Confirmer le mot de passe"
                                                        onChange={(e) => setPassword_confirm(e.target.value)}
                                                        aria-invalid={validPassword_confirm ? "false" : "true"}
                                                        aria-describedby="pwdcidnote"
                                                        onFocus={() => setPassword_confirmFocus(true)}
                                                        onBlur={() => setPassword_confirmFocus(false)}
                                                    />
                                                </div>
                                                <div id="pwdcidnote" className={password_confirmFocus && password_confirm && !validPassword_confirm
                                                    ? "mt-2" : "d-none"}>
                                                    <p style={{fontSize: "12px"}} className="text-danger text-wrap">
                                                        <i className="bi bi-info-circle"></i>
                                                        Les mots de passe ne sont pas identiques
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="topping"
                                                        name="topping"
                                                        value="yes"
                                                        checked={isChecked}
                                                        onChange={handleChangeCheckBox}
                                                    />
                                                        <label className="form-check-label" htmlFor="topping">I
                                                            agree and accept the <a href="#">terms and
                                                                conditions</a></label>
                                                        <div className="invalid-feedback">You must agree before
                                                            submitting.
                                                        </div>
                                                </div>
                                            </div>


                                            <p ref={errRef} className={errMsg ? "alert alert-danger" : "d-none"} aria-live="assertive">
                                                {errMsg}
                                            </p>
                                            {errorMsg ? (
                                                <div className="col-12 justify-content-center mb-4">
                                                    <div className="" style={{ color: "red" }}>
                                                        <i className="bi bi-exclamation-triangle"></i> {errorMsg}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="col-12">
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary w-100 btn-block">
                                                    {isSubmitting && (<><span
                                                        className="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                        <span className="">Loading...</span></>)}
                                                    <i className="bi bi-person-add"></i> S'enregistrer
                                                </button>
                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="small mb-0">Vous avez déjà un compte? <Link
                                                    to="/login">Connectez-vous Ici</Link></p>
                                            </div>
                                        </form>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
};

export default RegisterPage;