import axios from "axios";
import {BUSESHOURS_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    const cachedBusHours = await Cache.get("AdminBusHours");
    if(cachedBusHours) return cachedBusHours;
    return axios
        .get(BUSESHOURS_API)
        .then(response => {
            const bus_hours = response.data.busesHours;
            Cache.set("AdminBusHours", bus_hours);
            return bus_hours;
        });
}


async function find(id){

    const cachedBusHours = await Cache.get("AdminBusHours." + id);
    if(cachedBusHours) return cachedBusHours;
    return axios.get(BUSESHOURS_API + "/" + id)
        .then(response => {
            const busHour = response.data.busHour;
            Cache.set("AdminBusHours." + id, busHour);
            return busHour;
        });
}


function deleteBus(id) {

    return axios
        .delete(BUSESHOURS_API + "/" + id)
        .then(async response => {
            const cachedBusHours = await Cache.get("bus_hours");

            if(cachedBusHours){
                Cache.set("AdminBusHours", cachedBusHours.filter(i => i.id != id));
            }

            return response;
        });
}

function updateBusHour(id, bus){

    return axios.post(BUSESHOURS_API + "/store", {...bus, id: id})
        .then(async response => {
            const cachedBusHours = await Cache.get("AdminBusHours");

            const cachedBuseHour = await Cache.get("AdminBusHours." + id);

            if(cachedBuseHour){
                Cache.set("AdminBusHours." + id, response.data.busHour);
            }

            if(cachedBusHours){
                const index = cachedBusHours.findIndex(b => b.id === +id);
                cachedBusHours[index] = response.data.busHour;
            }

            return response;
        });
}

function create(busesHour){

    return axios.post(BUSESHOURS_API + "/store", busesHour)
        .then(async response => {
            const cachedBusHours = await Cache.get("AdminBusHours");

            if(cachedBusHours){
                Cache.set("AdminBusHours", [...cachedBusHours, response.data.busHour]);
            }

            return response;
        });
}

export default {
    findAll,
    find,
    create,
    updateBusHour,
    delete : deleteBus,
};