import React, { useState, useEffect, useRef } from 'react';
import {useLocalState} from "../../../utils/useLocalState";
import ajax from "../../../Services/fetchService";
import {Link, useParams, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Countdown from 'react-countdown'

const ValidateUserPage = () => {
    const noTwice = useRef(true);
    const [validationCode, setValidationCode] = useState("");
    const [user, setUser] = useState("");
    let {username} = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        fetch(`/auth/getUser/${username}`,{
            headers : {
                "Content-Type" : "application/json"
            },
            method : "GET",
        }).then((res) => res.json()).then((data) => {
            setUser(data.data);
        })
    },[]);


    const refreshPage = () => {
        navigate(0);
        window.location.reload();
    };
    function resendCode() {
        fetch(`/auth/resendValidationCode/${username}`,{
            headers : {
                "Content-Type" : "application/json"
            },
            method : "GET",
        }).then((res) => res.json()).then((data) => {
            setUser(data.data);
            localStorage.removeItem("end_date");
            window.location.reload();
            navigate("/validate-account/"+user.username);
        });
    }


    function validateUser() {
        const requestBody = {
            validationCode: validationCode,
            username: username
        };


        fetch("/auth/validateUserAccount",{
            headers : {
                "Content-Type" : "application/json"
            },
            method : "POST",
            body : JSON.stringify(requestBody)
        }).then((res) => res.json()).then((data) => {
            if (data.httpStatus === 200) {
                toast.success(data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate("/login")
            } else if (data.httpStatus === 400){
                console.log(data);
                toast.error(data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch((err) => {
            console.log(err.response);
        })
    }


    const Completionist = () => <span className="text-danger">Code invalide !</span>;

// Renderer callback with condition
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
        {minutes}:{seconds}
      </span>
            );
        }
    };

    const getLocalStorageValue = (s) => localStorage.getItem(s);

    const [data, setData] = useState(
        { date: Date.now(), delay: 60000 * 15 } //60 seconds
    );
    const wantedDelay = 60000 * 10; //60 s


    useEffect(() => {
        const savedDate = getLocalStorageValue("end_date");

        if (savedDate != null && !isNaN(savedDate)) {
            const currentTime = Date.now();
            const delta = parseInt(savedDate, 10) - currentTime;

            //Do you reach the end?
            if (delta > wantedDelay) {
                //Yes we clear uour saved end date
                if (localStorage.getItem("end_date").length > 0)
                    localStorage.removeItem("end_date");
            } else {
                //No update the end date
                setData({ date: currentTime, delay: delta });
            }
        }
    }, []);


    return (
        <main>
            <div className="container">

                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <Link to="/" className="logo d-flex align-items-center w-auto">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt=""/>
                                        <span className="d-none d-lg-block">KKS EXPRESS</span>
                                    </Link>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Activez votre compte</h5>
                                            <p className="text-center small">
                                                <Countdown
                                                    date={data.date + data.delay}
                                                    renderer={renderer}
                                                    onStart={(delta) => {
                                                        //Save the end date
                                                        if (localStorage.getItem("end_date") == null)
                                                            localStorage.setItem(
                                                                "end_date",
                                                                JSON.stringify(data.date + data.delay)
                                                            );
                                                    }}
                                                    onComplete={() => {
                                                        if (localStorage.getItem("end_date") != null)
                                                            localStorage.removeItem("end_date");
                                                    }}
                                                />
                                            </p>
                                        </div>

                                        <form className="row g-3 needs-validation" noValidate>

                                            <div className="col-12">
                                                <label htmlFor="username" className="form-label">Code</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">
                                                        <i className="bi bi-code"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="validationCode"
                                                        value={validationCode}
                                                        onChange={(e) => setValidationCode(e.target.value)}
                                                        className="form-control"
                                                        placeholder="code"
                                                    />
                                                    <div className="invalid-feedback">Please enter your username.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <Link className="" onClick={() => resendCode()}>Renvoyer le code?</Link>
                                            </div>
                                            <div className="col-12">
                                                <button onClick={() => validateUser()} className="btn btn-primary w-100" type="button">
                                                    <i className="bi bi-gear"></i> Valider le compte
                                                </button>
                                            </div>
                                            <div className="col-12">
                                                <p className="small mb-0">Vous n'avez pas de compte? <Link
                                                    to="/register">Créer un compte</Link></p>
                                            </div>
                                        </form>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
};

export default ValidateUserPage;