import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import FretCataloguesAPI from "../../../apis/adminApi/fretCatalogueAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";


const FretCatalogueIndex = () => {


    const [catalogues, setCatalogues] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchCatalogues = async () => {
        try{
            const data =  await FretCataloguesAPI.findAllFretCatalogues();
            setCatalogues(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des prix de frets !");
        }
    };


    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchCatalogues();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;


    const filteredCatalogues = catalogues.filter(
        b =>
            b.nomination.toLowerCase().includes(search.toLowerCase()) ||
            b.normal_price.toString().includes(search.toLowerCase()) ||
            b.quantity_r.toString().includes(search.toLowerCase()) ||
            b.price_r.toString().includes(search.toLowerCase()) ||
            b.frequency_r.toString().includes(search.toLowerCase()) ||
            b.percent_r.toString().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedCatalogues = Pagination.getData(
        filteredCatalogues,
        currentPage,
        itemsPerPage
    );



    let counter = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>La liste catalogue Fret</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">La liste catalogue Fret</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {catalogues ? catalogues.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-primary mt-3" to="/admin/fret_catalogues/add">
                                            <i className="bi bi-plus-circle"></i> Nouveau
                                        </Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                </div>


                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nomination</th>
                                        <th scope="col">Prix normal</th>
                                        <th scope="col">Qté en réduction</th>
                                        <th scope="col">Montant</th>
                                        <th scope="col">Frequence en réduction</th>
                                        <th scope="col">Rémise</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedCatalogues.map((cat) => (
                                            <tr key={cat.id}>
                                                <th scope="row">{counter++}</th>
                                                <td>{cat.nomination}</td>
                                                <td>{cat.normal_price} CDF</td>
                                                <td>{cat.quantity_r}</td>
                                                <td>{cat.price_r} CDF</td>
                                                <td>{cat.frequency_r}</td>
                                                <td>{cat.percent_r} %</td>
                                                <td>
                                                    <Link className="btn btn-sm btn-outline-success mx-1" to={`/admin/fret_catalogues/edit/${cat.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>}
                                </table>

                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredCatalogues.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredCatalogues.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FretCatalogueIndex;
