import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import  UserAPI from "../../../apis/adminApi/userAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import Select from "../../forms/Select";
import CheckBoxField from "../../forms/CheckBoxField";
import MyButton from "../../assets/MyButton";

const EditUser = () => {

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {userId} = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [checked, setChecked] = useState(false);

    const [utilisateur, setUser] = useState({
        name : "", email : "", phone : "", city : "", role : "", actived : "", id : userId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        name : "", email : "", phone : "", city : "", role : "", actived : "",
    });

    //Recuperation d'une facture
    const fetchUser = async id => {
        try{
            const { name, email, phone, city, role, actived } = await UserAPI.find(id);
            setUser({ name, email, phone, city, role, actived });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'utilisateur démandé !");
            navigate("/admin");
        }
    };

    const handleChex = () => {
        setChecked(!checked);
        if (!checked === true) {
            setUser({...utilisateur, actived : 1});
        }else{
            setUser({...utilisateur, actived : 0});
        }

    };


    useEffect(() => {
        setEditing(true);
        fetchUser(userId);
    }, [userId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setUser({...utilisateur, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await UserAPI.update(userId, utilisateur);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Utilisateur modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;
            const {error} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification de l'utilisateur !");
            }else if (error) {
                toast.error(error);
            }
        }
    };

    let redirect = "";
    if (utilisateur.role === "ROLE_VENDOR") {
        redirect = "vendeurs";
    }else if (utilisateur.role === "ROLE_MANAGER") {
        redirect = "managers";
    }else if (utilisateur.role === "ROLE_BUS_DRIVER") {
        redirect = "drivers";
    }else if (utilisateur.role === "ROLE_BUS_MANAGER") {
        redirect = "bus_managers";
    }else if (utilisateur.role === "ROLE_FRET") {
        redirect = "fretOperateurs";
    }else if (utilisateur.role === "ROLE_MANIFEST") {
        redirect = "manifests";
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un utilisateur</h5>

                                        <Field
                                            name="name"
                                            error={errors.name}
                                            value={utilisateur.name}
                                            onChange={handleChange}
                                            label="Nom Complet"
                                        />

                                        <Field
                                            name="email"
                                            type="email"
                                            error={errors.email}
                                            value={utilisateur.email}
                                            onChange={handleChange}
                                            label="Email"
                                        />


                                        <Field
                                            name="phone"
                                            error={errors.phone}
                                            value={utilisateur.phone}
                                            onChange={handleChange}
                                            label="Telephone"
                                        />


                                        <Field
                                            name="city"
                                            error={errors.city}
                                            value={utilisateur.city}
                                            onChange={handleChange}
                                            label="Ville"
                                        />


                                        <Select name="role" label="Role" value={utilisateur.role} error={errors.role} onChange={handleChange}>
                                            <option value="ROLE_MANAGER">Gestionnaire bus</option>
                                            <option value="ROLE_VENDOR">Vendeur</option>
                                            <option value="ROLE_FRET">Operateur Fret</option>
                                            <option value="ROLE_BUS_DRIVER">Chauffeur</option>
                                            <option value="ROLE_BUS_MANAGER">Escorteur</option>
                                            <option value="ROLE_ACCOUNTING">Comptable</option>
                                            <option value="ROLE_MANIFEST">Manifeste</option>
                                        </Select>

                                        <CheckBoxField
                                            name="actived"
                                            value={utilisateur.actived}
                                            label="Actif"
                                            onChange={handleChex}
                                            checked={utilisateur.actived ? "checked" : ""}
                                        />

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to={`/admin/${redirect}`} className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form> }
                </div>
            </div>
        </div>
    );
};

export default EditUser;
