import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import ajax from '../../Services/fetchService';
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../Services/userProvider";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Moment from "moment";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";

const AddReservationAssigngment = () => {
    const componentRef = useRef();
    const { handleSubmit, formState } = useForm();
    const { isSubmitting } = formState;
    const navigate = useNavigate();
    const user = useUser();

    const [bus_id, setBusId] = useState("");
    const [p_id, setPid] = useState("");
    const [busManager, setBusManager] = useState("");
    const [busDriver, setBusDriver] = useState("");
    const [busHour, setBusHour] = useState("");
    const [destination, setDestination] = useState("");
    const [reservation_date, setreseraion_date] = useState("");

    const [buses, setBuses] = useState();
    const [vendeurs, setVendeurs] = useState();
    const [dailyAssignments, setDailyAssignments] = useState();

    const [trajets, setTrajets] = useState();
    useEffect(() => {
        ajax("/api/v1/manager/trajets/allForTransite", "GET", user.jwt).then(
            (response) => {
                setTrajets(response.trajets);
            })
    },[]);

    useEffect(() => {
        ajax("/api/v1/manager/buses/dailyReservationAllAssignments", "GET", user.jwt).then(
            (response) => {
                setDailyAssignments(response.assignments);
            })
    },[]);

    const tooltip = (chaffeuer, escorteur) => (
        <Tooltip className="in" id="tooltip">
            <ul><li>Chaf: {chaffeuer}</li><li>Escort: {escorteur}</li></ul>
        </Tooltip>
    );

    const [busesHours, setBusesHours] = useState(null);

    useEffect(() => {
        ajax("/api/v1/admin/busesHours/all", "GET", user.jwt).then(
            (response) => {
                setBusesHours(response.busesHours);
            })
    },[]);

    useEffect(() => {
        ajax("/api/v1/manager/buses/availablesDailyBus", "GET", user.jwt).then(
            (response) => {
                setBuses(response.buses);
            })
    },[]);

    useEffect(() => {
        ajax("/api/v1/manager/users/managerVendeursForTransit", "GET", user.jwt).then(
            (response) => {
                setVendeurs(response.vendeurs);
                //console.log(vendeurs);
            })
    },[]);

    const [bus_managers, setBusManagers] = useState(null);

    useEffect(() => {
        ajax("/api/v1/manager/users/bus_managers", "GET", user.jwt).then(
            (response) => {
                setBusManagers(response.bus_managers);
            })
    },[]);

    const [drivers, setDrivers] = useState(null);

    useEffect(() => {
        ajax("/api/v1/manager/users/drivers", "GET", user.jwt).then(
            (response) => {
                setDrivers(response.drivers);
            })
    },[]);

    const requestBody = {
        bus_id : bus_id,
        p_id : p_id,
        reservation : 2,
        genre : 0,
        busManager : busManager,
        busDriver : busDriver,
        busHour : busHour,
        reservation_date : reservation_date,
        destination : destination,
    };
    let countir = 1;

    function save(){
        return new Promise(resolve => {
            setTimeout(() => {
                if (requestBody.bus_id !== "" || requestBody.p_id !== "" || requestBody.busManager
                    || requestBody.busHour !== "" || requestBody.busDriver || requestBody.reservation_date !== ""){
                    ajax(`/api/v1/manager/buses/addBusAssignment`, "POST", user.jwt, requestBody)
                        .then((response) => {
                            if (response.status === 422) {
                                const errorItems = Object.keys(response.errors).map( (key, i) => {
                                    const message = response.errors[key][0];
                                    //setErrorMsg(key+" : "+message);
                                    toast.error(message, {
                                        position: toast.POSITION.BOTTOM_CENTER,
                                        autoClose: 4000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                });
                            }else if(response.status === 400){
                                toast.error(response.message, {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    autoClose: 4000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }else{
                                ajax("/api/v1/manager/buses/dailyReservationAllAssignments", "GET", user.jwt).then(
                                    (response) => {
                                        setDailyAssignments(response.assignments);
                                    });
                                toast.success(response.message, {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setBusId("");
                                navigate("/manager/new_assignment");
                            }

                        });
                } else{
                    toast.error("Aucun champ ne peut etre vide", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                resolve();
            }, 2000);
        });
    }

    let counter = 1;


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <form onSubmit={handleSubmit(save)}>
                            <div className="card-body">
                                <h2 className="card-title">Affecter un bus à un point de vente</h2>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="mt-1">
                                            <label htmlFor="bus_id">Bus disponibles</label>
                                            <select
                                                className="form-control form-select"
                                                name="bus_id"
                                                value={bus_id}
                                                onChange={(e) => setBusId(e.target.value)}
                                                aria-label="Bus disponible">
                                                <option value="">Choisir</option>
                                                {
                                                    buses ? (buses.map((bus) => (
                                                        <option key={bus.id} value={bus.bus_id}>{bus.bus_code} - {bus.bus_numberplate}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor="destination">Destination</label>
                                            <select
                                                className="form-control form-select"
                                                name="destination"
                                                value={destination}
                                                onChange={(e) => setDestination(e.target.value)}
                                                aria-label="Destination">
                                                <option value="">Choisir</option>
                                                {
                                                    trajets ? (trajets.map((trajet) => (
                                                        <option key={trajet.id} value={trajet.id}>{trajet.from} - {trajet.to}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-1">
                                            <label htmlFor="busDriver">Chauffeur</label>
                                            <select
                                                className="form-control form-select"
                                                name="busDriver"
                                                value={busDriver}
                                                onChange={(e) => setBusDriver(e.target.value)}
                                                aria-label="Chauffeur">
                                                <option value="">Choisir</option>
                                                {
                                                    drivers ? (drivers.map((driver) => (
                                                        <option key={driver.id} value={driver.id}>{driver.name}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor="busHour">Heure de bus</label>
                                            <select
                                                className="form-control form-select"
                                                name="busHour"
                                                value={busHour}
                                                onChange={(e) => setBusHour(e.target.value)}
                                                aria-label="Heure de bus">
                                                <option value="">Choisir</option>
                                                {
                                                    busesHours ? (busesHours.map((busesHour) => (
                                                        <option key={busesHour.id} value={busesHour.id}>{busesHour.bus_hour}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-1">
                                            <label htmlFor="p_id">Agence ou Point de vente</label>
                                            <select
                                                className="form-control form-select"
                                                name="p_id"
                                                value={p_id}
                                                onChange={(e) => setPid(e.target.value)}
                                                aria-label="point de vente">
                                                <option value="">Choisir</option>
                                                {
                                                    vendeurs ? (vendeurs.map((vendeur) => (
                                                        <option key={vendeur.id} value={vendeur.id}>
                                                            Agence: {vendeur.pos_city} |
                                                            {vendeur.pos_name}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor="reservation_date">Date de reservation</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="reservation_date"
                                                id="reservation_date"
                                                value={reservation_date}
                                                onChange={(e) => setreseraion_date(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-1">
                                            <label htmlFor="busManager">Escorteur</label>
                                            <select
                                                className="form-control form-select"
                                                name="busManager"
                                                value={busManager}
                                                onChange={(e) => setBusManager(e.target.value)}
                                                aria-label="Escorteurs">
                                                <option value="">Choisir</option>
                                                {
                                                    bus_managers ? (bus_managers.map((gerant) => (
                                                        <option key={gerant.id} value={gerant.id}>{gerant.name}</option>
                                                    ))) : (<></>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <button disabled={isSubmitting} className="btn btn-primary mr-1 mt-3">
                                    {isSubmitting &&
                                    <><span className="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>...</>
                                    } Envoyer
                                </button>
                                <Link to="/manager/buses" className="btn btn-danger mx-2 mt-3">Annuler</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Total bus de reservation aujourd'hui : <b className="text-primary">{dailyAssignments ? dailyAssignments.length : 0} </b>
                            </h2>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Depart</th>
                                    <th scope="col">Agence</th>
                                    <th scope="col">Provenance</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Heure</th>
                                    <th scope="col">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dailyAssignments ? (dailyAssignments.map((assignment) => (
                                        <tr key={assignment.id}>
                                            <th scope="row">{countir++}</th>
                                            <td>
                                                <b>{assignment.bus_numberplate}, S: {assignment.bus_number_of_seats}</b>
                                            </td>
                                            <td>{assignment.bus_hour}</td>
                                            <td>{assignment.p_name}</td>
                                            <td>{assignment.from}</td>
                                            <td>{assignment.to}</td>
                                            <td>{Moment(assignment.created_at).format("HH:mm")}</td>
                                            <td>
                                                <ButtonToolbar>
                                                    <OverlayTrigger placement="top"
                                                                    overlay={tooltip(assignment.driver_name,assignment.manager_name)}>
                                                        <Button className="badge btn btn-dark">
                                                            <i className="bi bi-info-circle"></i></Button>
                                                    </OverlayTrigger>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>
                                    ))) : (<></>)
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddReservationAssigngment;
