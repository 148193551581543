import React from 'react';


const Select = ({name, label, value, onChange, error = "", children}) => 
(<div className="mb-3">
    <label htmlFor={name} className="form-label">{label}</label>
    <select onChange={onChange} name={name} value={value}  id={name}
            className={"form-control " + ( error && "is-invalid")}>
        {children}
    </select>
    {error && <div className="invalid-feedback">{error}</div>}
</div> 
);
 
export default Select;