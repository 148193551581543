import React from "react";
import { ReactComponent as Loader } from './loader.svg'


const MyButton = ({ onSubmit, text, loading = false, disabled, cssClass="btn btn-success" }) => {
    return (
        <button className={cssClass} onClick={onSubmit} disabled={disabled}>
            {!loading ? text : <Loader className="spinner" />}
        </button>
    )
};

export default MyButton