import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FretCatAPI from "../../../apis/adminApi/fretCatalogueAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";

const EditFretCatalogue = () => {

    let {catId} = useParams();
    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);

    const [showLoader, setShowLoader] = useState(false);

    const [catalogue, setCatalogue] = useState({
        nomination : "", normal_price : "", quantity_r : "", price_r : "", frequency_r : "", percent_r : "", id : catId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        nomination : "", normal_price : "", quantity_r : "", price_r : "", frequency_r : "", percent_r : ""
    });


    const fetchCatalogue = async id => {
        try{
            const { nomination, normal_price, quantity_r, price_r, frequency_r, percent_r } = await FretCatAPI.find(id);
            setCatalogue({ nomination, normal_price, quantity_r, price_r, frequency_r, percent_r });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger la facture démandée !");
            navigate("/admin/fret_catalogues");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchCatalogue(catId);
    }, [catId]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setCatalogue({...catalogue, [name] : value})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await FretCatAPI.updateFretCatalogue(catId, catalogue);
            toast.success("Prix modifié avec succès");
            setTimeout(() => setShowLoader(false), 1000);
        }catch({response}){
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du catalogue !");
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    {loading && <FormContentLoader />}

                    {!loading &&  <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un produit catalogue Fret</h5>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <Field
                                                    name="nomination"
                                                    error={errors.nomination}
                                                    value={catalogue.nomination}
                                                    onChange={handleChange}
                                                    label="Nomination"
                                                    placeholder="Nomination"
                                                />

                                                <Field
                                                    name="normal_price"
                                                    error={errors.normal_price}
                                                    value={catalogue.normal_price}
                                                    onChange={handleChange}
                                                    label="Prix normal"
                                                    placeholder="000 CDF"
                                                    type="number"
                                                />

                                                <Field
                                                    name="quantity_r"
                                                    error={errors.quantity_r}
                                                    value={catalogue.quantity_r}
                                                    onChange={handleChange}
                                                    label="Quantité pour reduction"
                                                    placeholder="000 CDF"
                                                    type="number"
                                                />

                                            </div>
                                            <div className="col-md-6">

                                                <Field
                                                    name="price_r"
                                                    error={errors.price_r}
                                                    value={catalogue.price_r}
                                                    onChange={handleChange}
                                                    label="Prix en reduction"
                                                    placeholder="000 CDF"
                                                    type="number"
                                                />

                                                <Field
                                                    name="frequency_r"
                                                    error={errors.frequency_r}
                                                    value={catalogue.frequency_r}
                                                    onChange={handleChange}
                                                    label="Frequnce du client pour redution"
                                                    placeholder="00"
                                                    type="number"
                                                />

                                                <Field
                                                    name="percent_r"
                                                    error={errors.percent_r}
                                                    value={catalogue.percent_r}
                                                    onChange={handleChange}
                                                    label="Rémise en pourcentage"
                                                    placeholder="00"
                                                    type="number"
                                                />

                                            </div>
                                        </div>

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/fret_catalogues" className="btn btn-info mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>}
                </div>
            </div>
        </div>
    );
};

export default EditFretCatalogue;
