import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from "moment";

class ComponentToPrintFret extends React.Component {

    render() {
        const bus = this.props.bus;
        const param = this.props.param;
        const keyCity = this.props.keyCity;
        let counter1 = 1;
        let counter2 = 1;

        const mystyle = {
            color: "black",
            padding: "20px 20px",
            fontSize: "15px",
        };

        const ticketsByDestination = (data) => {
            const destinations = data.tickets.map((key, value) => key = key.destination);
            let data_table = [];
            destinations.forEach((val) => {
                if (data_table[val]) {
                    data_table[val] += 1;
                } else {
                    data_table[val] = 1;
                }
            });
            return data_table;
        };

        return (
            <>
                {bus ? (<div style={mystyle}>
                        <center><img  src={logo} alt="logo" /></center>
                        <p>{param ? param.company_address : ""} <br/>
                            Date : Le {Moment(bus.created_at).format("DD-MM-YYYY")} <br/>
                            Bus : <b>{bus.bus_numberplate}</b> <br/>
                            Provenance : <b>{bus.from}</b>, Destination : <b>{bus.to}</b>, Depart : <b>{bus.bus_hour}</b> <br/>
                            Chauffeur : <b>{bus.driver_name}</b>, Escorteur : <b>{bus.manager_name}</b>
                        </p>

                        <hr/>
                        {bus.colis.length > 0 ? (
                            <>
                                <h3><b>MANIFEST COLIS - <span className="text-primary">{keyCity}</span></b></h3>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">N°</th>
                                        <th scope="col">Exp</th>
                                        <th scope="col">Tél</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Destination</th>
                                        <th scope="col">Destinataire</th>
                                        <th scope="col">Tél</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {bus.colis.map((fret) => (
                                        keyCity === fret.city_to ? (<tr key={fret.id}>
                                            <td>{counter2++}</td>
                                            <td>{fret.expediteur}</td>
                                            <td>{fret.expediteur_phone}</td>
                                            <td><b>{fret.reference}</b></td>
                                            <td>{fret.name}</td>
                                            <td className="text-center"><b>{fret.code}</b></td>
                                            <td className="text-center"><b>{fret.city_to}</b></td>
                                            <td>{fret.recipient}</td>
                                            <td>{fret.recipient_phone}</td>

                                        </tr>) : (<></>)

                                    ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (<></>)}
                        <hr/>
                        <h3><b>MANIFEST PASSAGERS</b></h3>
                        <div className="fw-bold">
                            {(ticketsByDestination(bus).Lubumbashi && keyCity === "Lubumbashi") && (<span style={{fontSize: "20px"}}>
                                                Lubumbashi : {ticketsByDestination(bus).Lubumbashi}</span>)}

                            {(ticketsByDestination(bus).Kolwezi && keyCity === "Kolwezi") && (<span style={{fontSize: "20px"}}>
                                                Kolwezi : {ticketsByDestination(bus).Kolwezi}</span>)}

                            {(ticketsByDestination(bus).Likasi && keyCity === "Likasi") && (<span style={{fontSize: "20px"}}>
                                                Likasi : {ticketsByDestination(bus).Likasi}</span>)}

                            {(ticketsByDestination(bus).Fungurume && keyCity === "Fungurume") && (<span style={{fontSize: "20px"}}>
                                                Fungurume : {ticketsByDestination(bus).Fungurume}</span>)}

                            {(ticketsByDestination(bus).Kasumbalesa && keyCity === "Kasumbalesa") && (<span style={{fontSize: "20px"}}>
                                                Kasumbalesa : {ticketsByDestination(bus).Kasumbalesa}</span>)}

                            {(ticketsByDestination(bus).Sakania && keyCity === "Sakania") && (<span style={{fontSize: "20px"}}>
                                                Sakania : {ticketsByDestination(bus).Sakania}</span>)}
                        </div>
                        <table className="table table-hover table-striped">
                            <thead>
                            <tr>
                                <th scope="col">N°</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Tél</th>
                                <th scope="col">Destination</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bus.tickets.map((ticket) => (
                                keyCity === ticket.destination ? (<tr key={ticket.id}>
                                    <td>{counter1++}</td>
                                    <td>{ticket.customer} <br/> <small>{ticket.bus_plaque}</small></td>
                                    <td>{ticket.bus_number}</td>
                                    <td className="text-center"><b>{ticket.destination}</b></td>
                                </tr>) : (<></>)

                            ))}
                            </tbody>
                        </table>
                    </div>):
                    (<></>)}
            </>
        );
    }
};
export default ComponentToPrintFret;
