import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const ManagerSideBar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/manager' ? 'nav-link ' : 'nav-link collapsed'} to="/manager">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>


                <li className="nav-heading">Bus & Disponibilité des bus</li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/buses' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/buses">
                        <i className="bi bi-bus-front"></i>
                        <span>Les bus</span>
                    </Link>
                </li>

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/manager/buses/add_availablity' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/buses/add_availablity">*/}
                        {/*<i className="bi bi-plus-circle"></i>*/}
                        {/*<span>Ajouter</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                <li className="nav-item">
                    <Link className={pathname === '/manager/buses/availablities' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/buses/availablities">
                        <i className="bi bi-joystick"></i>
                        <span>Disponibilités des bus</span>
                    </Link>
                </li>


                <li className="nav-heading">AFFECTATIONS DES BUS</li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/new_assignment' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/new_assignment">
                        <i className="bx bxs-car"></i>
                        <span>Bus direct</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/new_transite_assignment' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/new_transite_assignment">
                        <i className="bx bxs-car-wash"></i>
                        <span>Bus de transite</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/new-sheduled-assignment' ? 'nav-link ' : 'nav-link collapsed'}
                          to="/manager/new-sheduled-assignment">
                        <i className="bx bxs-car-mechanic"></i>
                        <span>Programmé un bus</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/manager/all_assignments' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/all_assignments">
                        <i className="bi bi-bus-front"></i>
                        <span>Toutes les affectations</span>
                    </Link>
                </li>

                {/*<li className="nav-heading">Agents</li>*/}

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/manager/drivers' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/drivers">*/}
                        {/*<i className="bi bi-person"></i>*/}
                        {/*<span>Chauffeurs</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

                {/*<li className="nav-item">*/}
                    {/*<Link className={pathname === '/manager/gerants' ? 'nav-link ' : 'nav-link collapsed'} to="/manager/gerants">*/}
                        {/*<i className="bi bi-person"></i>*/}
                        {/*<span>Escorteurs</span>*/}
                    {/*</Link>*/}
                {/*</li>*/}

            </ul>

        </aside>
    );
};

export default ManagerSideBar;