import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import BusesAPI from "../../apis/managerApi/busAPI";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import Pagination from "../assets/Pagination";
import TableLoader from "../loaders/TableLoader";

const Allavalablities = () => {

    const [availablities, setAvailablities] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    let counter = 1;

    const fetchBusesAvailabilities = async () => {
        try{
            const data =  await BusesAPI.findAllAvailabilities();
            setAvailablities(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des disponibilités des bus !");
        }
    };

    useEffect(() => {
        fetchBusesAvailabilities();
        //console.log(availablities);
    },[]);

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredAvailabilities = availablities.filter(
        b =>
            b.bus_code.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.pattern.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedAvailabilities = Pagination.getData(
        filteredAvailabilities,
        currentPage,
        itemsPerPage
    );

    let countir = 1;

    const tooltip = (data) => (
        <Tooltip className="in" id="tooltip">
            <p>{data}</p>
        </Tooltip>
    );


    return (
        <>
            <div className="pagetitle">
                <h1>Les disponibilités des bus</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les disponibilités des bus</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-5">
                                        <h5 className="card-title">Total {availablities ? availablities.length : 0}</h5>
                                    </div>
                                    <div className="col-7">
                                        {/*<div className="form-group mt-2">*/}
                                            {/*<input type="text" onChange={handleSearch}*/}
                                                   {/*value={search} className="form-control"*/}
                                                   {/*placeholder="Rechercher tout ..." />*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bus</th>
                                        <th scope="col">Plaque</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Heure</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedAvailabilities.map((dispo) => (
                                            <tr key={dispo.id}>
                                                <th scope="row">{countir++}</th>
                                                <td>N° {dispo.bus_code}</td>
                                                <td>{dispo.bus_numberplate}</td>
                                                <td>Le {Moment(dispo.created_at).format("DD/MM/YYYY")}</td>
                                                <td>{Moment(dispo.created_at).format("HH:mm")}</td>
                                                <td>
                                                    {dispo.available === 1  ?(<span className="text-success"><i className="bi bi-check-all"></i></span>)
                                                        :(<ButtonToolbar>
                                                            <OverlayTrigger placement="top" overlay={tooltip(dispo.pattern)}>
                                                                <Button className="badge btn btn-danger">Indispo</Button>
                                                            </OverlayTrigger>
                                                        </ButtonToolbar>)
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    </tbody>}
                                </table>

                                {loading && <TableLoader width={300} height={350} />}

                                {itemsPerPage < filteredAvailabilities.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredAvailabilities.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Allavalablities;
