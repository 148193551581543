import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import BusesAPI from "../../apis/managerApi/busAPI";
import FormContentLoader from "../loaders/FormContentLoader";
import Select from "../forms/Select";
import MyButton from "../assets/MyButton";
import Field from "../forms/Field";

const EditAssignment = () => {

    let {assiId} = useParams();
    const navigate = useNavigate();
    const { formState } = useForm();
    const { isSubmitting } = formState;
    const [buses, setBuses] = useState([]);
    const [vendeurs, setVendeurs] = useState([]);
    const [trajets, setTrajets] = useState([]);
    const [loading, setLoading] = useState([]);
    const [bus_managers, setBusManagers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        bus_id : "", trajet_id : "", bus_hour_id: "", driver_id: "", p_id : "",
        vendor_id: "", manager_id: "", bus_hour: "",
    });

    const [assignment, setAssignment] = useState({
        bus_id : "", trajet_id : "", bus_hour_id: "", bus_hour : "", driver_id: "", p_id : "",
        vendor_id: "", manager_id: "", id : assiId
    });

    const fetchAssignment = async id => {
        try{
            const {bus_id, trajet_id, bus_hour_id, bus_hour, driver_id, p_id, vendor_id, manager_id} = await BusesAPI.findOneAssignment(id);
            setAssignment({bus_id, trajet_id, bus_hour_id, bus_hour, driver_id, p_id, vendor_id, manager_id});
            //setTrajet({traject});
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger l'affectation démandée !");
            navigate("/manager/new_assignment");
        }
    };

    const fetchTrajets = async () => {
        try{
            const data =  await BusesAPI.findTrajects();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };


    const fetchAvailableDailyBus = async () => {
        try{
            const data =  await BusesAPI.findDailyAvailabilities();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus !");
        }
    };

    const fetchVendeurs = async () => {
        try{
            const data =  await BusesAPI.findVendors();
            setVendeurs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des vendeurs !");
        }
    };

    const fetchExcorteurs = async () => {
        try{
            const data =  await BusesAPI.findBusExcortors();
            setBusManagers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des excorteurs !");
        }
    };

    const fetchDrivers = async () => {
        try{
            const data =  await BusesAPI.findDrivers();
            setDrivers(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des chauffeurs !");
        }
    };

    useEffect(() => {
        fetchAssignment(assiId);
    },[assiId]);

    useEffect(() => {
        fetchTrajets();
        fetchAvailableDailyBus();
        fetchVendeurs();
        fetchExcorteurs();
        fetchDrivers();
    },[]);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setAssignment({...assignment, [name] : value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await BusesAPI.updateAssignment(assiId, assignment);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Affectation modifié avec succès");
            navigate("/manager/new_assignment");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification !");
            }else{
                toast.error(response.data.message);
            }
        }
    };


    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            {loading && <FormContentLoader />}
                            {!loading && <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <h2 className="card-title">Modification d'une affectation</h2>
                                    <div className="row">
                                        <div className="col-md-3">

                                            <Select name="bus_id" label="Bus disponible" value={assignment.bus_id}
                                                    error={errors.bus_id} onChange={handleChange}>
                                                {buses.map(bus =>
                                                    <option key={bus.id} value={bus.bus_id}>
                                                        {bus.bus_code} - {bus.bus_numberplate}
                                                    </option>)
                                                }
                                            </Select>

                                            <Select name="trajet_id" label="Destination" value={assignment.trajet_id}
                                                    error={errors.trajet_id} onChange={handleChange}>
                                                {trajets.map(trajet =>
                                                    <option key={trajet.id} value={trajet.id}>{trajet.from} - {trajet.to}</option>)
                                                }
                                            </Select>
                                        </div>
                                        <div className="col-md-3">

                                            {/*<Select name="bus_hour_id" label="Heure de bus" value={assignment.bus_hour_id}*/}
                                                    {/*error={errors.bus_hour_id} onChange={handleChange}>*/}
                                                {/*{busesHours.map(busH =>*/}
                                                    {/*<option key={busH.id} value={busH.id}>{busH.bus_hour}</option>)*/}
                                                {/*}*/}
                                            {/*</Select>*/}

                                            <Field
                                                name="bus_hour"
                                                error={errors.bus_hour}
                                                value={assignment.bus_hour}
                                                onChange={handleChange}
                                                label="Heure de bus"
                                                placeholder="Ex: 06h30"
                                            />

                                            <Select name="driver_id" label="Chauffeur" value={assignment.driver_id}
                                                    error={errors.driver_id} onChange={handleChange}>
                                                {drivers.map(driver =>
                                                    <option key={driver.id} value={driver.id}>{driver.name}</option>)
                                                }
                                            </Select>


                                        </div>
                                        <div className="col-md-3">

                                            <Select name="p_id" label="Agence ou vendeur" value={assignment.p_id}
                                                    error={errors.p_id} onChange={handleChange}>
                                                {vendeurs.map(vendeur =>
                                                    <option key={vendeur.id} value={vendeur.id}>
                                                       {vendeur.name} |
                                                        {vendeur.pos_name}</option>)
                                                }
                                            </Select>

                                            <Select name="manager_id" label="Excorteur" value={assignment.manager_id}
                                                    error={errors.manager_id} onChange={handleChange}>
                                                {bus_managers.map(gerant =>
                                                    <option key={gerant.id} value={gerant.id}>{gerant.name}</option>)
                                                }
                                            </Select>

                                        </div>
                                    </div>

                                    <MyButton
                                        text="Envoyer"
                                        onSubmit={handleSubmit}
                                        loading={showLoader}
                                        disabled={showLoader}
                                    />
                                    <Link to="/manager" className="btn btn-danger mx-2">Annuler</Link>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAssignment;
