import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import UsersAPI from "../../../apis/adminApi/userAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";

const FretOperatorIndex = () => {


    const [vendeurs, setVendeurs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //Permet d'aller recuperer les invoices
    const fetchVendeurs = async () => {
        try{
            const data =  await UsersAPI.findAllFretVendeurs();
            setVendeurs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des vendeur !");
        }
    };

    useEffect(() => {
        fetchVendeurs();
    },[]);



    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredVendeurs = vendeurs.filter(
        v =>
            v.name.toLowerCase().includes(search.toLowerCase()) ||
            v.email.toLowerCase().includes(search.toLowerCase()) ||
            v.phone.toLowerCase().includes(search.toLowerCase()) ||
            v.city.toLowerCase().includes(search.toLowerCase()) ||
            v.pos_city.toLowerCase().includes(search.toLowerCase()) ||
            v.pos_name.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedVendeurs = Pagination.getData(
        filteredVendeurs,
        currentPage,
        itemsPerPage
    );



    let count = 1;


    return (
        <>
            <div className="pagetitle">
                <h1>Les vendeurs</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les operateurs fret KKS</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <h5 className="card-title">Total {vendeurs ? vendeurs.length : 0}</h5>
                                    </div>
                                    <div className="col-6">
                                        <Link className="btn btn-sm btn-success mt-3" to="/admin/users/add">
                                            <i className="bi bi-plus-circle"></i> Ajouter un operateur fret
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="form-group">
                                            <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Rechercher ..." />
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Telephone</th>
                                        <th scope="col">Ville de residence</th>
                                        <th scope="col">Ville PdV</th>
                                        <th scope="col">Point de vente</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                    </thead>
                                    {!loading && <tbody>
                                    {paginatedVendeurs.map((vendeur) => (
                                        <tr key={vendeur.id}>
                                            <th scope="row">{count++}</th>
                                            <td>{vendeur.name}</td>
                                            <td>{vendeur.email}</td>
                                            <td>{vendeur.phone}</td>
                                            <td>{vendeur.city}</td>
                                            <td>{vendeur.pos_city}</td>
                                            <td>{vendeur.pos_name}</td>
                                            <td>
                                                {vendeur.actived === 0 ?
                                                    (<><i className="text-danger bi bi-x-circle-fill"></i></>) :
                                                    (<><i className="text-success bi bi-check-square-fill"></i></>)
                                                }
                                            </td>

                                            <td>
                                                <div className="btn-group badge" role="group" aria-label="Basic example">
                                                    <Link className="btn btn-sm btn-success" to={`/admin/users/edit/${vendeur.id}`}>
                                                        <i className="bi bi-pen-fill"></i>
                                                    </Link>
                                                    <Link className="btn btn-sm btn-outline-primary" to={`/admin/vendeurs/affecteToPv/${vendeur.id}`}>
                                                        Affectation
                                                    </Link>
                                                    <Link className="btn btn-sm btn-outline-danger" to={`/admin/users/updateUserPassword/${vendeur.id}`}>
                                                        Mot de passe
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                    </tbody> }
                                </table>

                                {loading && <TableLoader width={500} height={550} />}

                                {itemsPerPage < filteredVendeurs.length &&
                                <Pagination
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    length={filteredVendeurs.length}
                                    onPageChange={handleChangeCurrentPage}
                                />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FretOperatorIndex;
