import axios from "axios";
import {AGENCY_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    const cachedAgencies = await Cache.get("AdminAgencies");
    if(cachedAgencies) return cachedAgencies;
    return axios
        .get(AGENCY_API)
        .then(response => {
            const agencies = response.data.agencies;
            Cache.set("AdminAgencies", agencies);
            return agencies;
        });
}


async function find(id){

    const cachedAgencies = await Cache.get("AdminAgencies." + id);
    if(cachedAgencies) return cachedAgencies;
    return axios.get(AGENCY_API + "/" + id)
        .then(response => {
            const agency = response.data.agency;
            Cache.set("AdminAgencies." + id, agency);
            return agency;
        });
}


function deletePv(id) {

    return axios
        .delete(AGENCY_API + "/" + id)
        .then(async response => {
            const cachedAgencies = await Cache.get("agencies");

            if(cachedAgencies){
                Cache.set("AdminAgencies", cachedAgencies.filter(i => i.id != id));
            }

            return response;
        });
}

function updatePv(id, agency){

    return axios.post(AGENCY_API + "/store", {...agency, id: id})
        .then(async response => {

            const cachedAgencies = await Cache.get("AdminAgencies");

            const cachedAgency = await Cache.get("AdminAgencies." + id);

            if(cachedAgency){
                Cache.set("AdminAgencies." + id, response.data.agency);
            }

            if(cachedAgencies){
                const index = cachedAgencies.findIndex(b => b.id === +id);
                cachedAgencies[index] = response.data.agency;
            }

            return response;
        });
}

function create(agency){

    return axios.post(AGENCY_API + "/store", agency)
        .then(async response => {
            const cachedAgencies = await Cache.get("AdminAgencies");

            if(cachedAgencies){
                Cache.set("AdminAgencies", [...cachedAgencies, response.data.agency]);
            }

            return response;
        });
}

export default {
    findAll,
    find,
    create,
    updatePv,
    delete : deletePv,
};