import React,{ createContext, useState } from "react";
import AuthAPI from "../Services/auth/authAPI";

const AuthContext = createContext({
    auth : false, setAuth: value => {}
});

export const AuthProvider = ({ children }) => {
    //const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
    const [auth, setAuth] = useState(AuthAPI.isAuthenticated());

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
