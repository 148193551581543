  import React, { useEffect, useState, useRef } from 'react';
 import {Link} from "react-router-dom";
 import {useForm} from "react-hook-form";
 import PrintComponent from "./printer";
 import {toast} from "react-toastify";
 import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
 import Field from "../../forms/Field";
 import Select from "../../forms/Select";
 import Pagination from "../../assets/Pagination";
 import TableLoader from "../../loaders/TableLoader";
 import MyButton from "../../assets/MyButton";
 import CheckBoxField from "../../forms/CheckBoxField";
 import CustomAPI from "../../../apis/customerApi/VendeurAPI";
 import Moment from "moment/moment";
  import ManifestAPI from "../../../apis/manifestApi/manifestAPI";


 const NewVenteReservation = () => {

     const { formState } = useForm();
     const [showLoader, setShowLoader] = useState(false);
     const { isSubmitting } = formState;
     const [loading, setLoading] = useState(true);
     const [trajs, setTrajets] = useState([]);
     const [total, setTotal] = useState("");
     const [assign, setAssign] = useState({id : ""});
     const [dailyTickets, setDailyTickets] = useState([]);
     const [buses, setBuses] = useState([]);
     const [setting, setSetting] = useState([]);
     const [dailybuses, setDailybuses] = useState([]);

     const [checked, setChecked] = useState(false);

     const [ticket, setTicket] = useState({
         customer : "", phone:"", destination : "", assignment_id : 0 , bonus : 0
     });

     const [errors, setErrors] = useState({
         customer : "", phone:"", destination : "", assignment_id : ""
     });

     const fetchDailyAssignments = async () => {
         try{
             const data =  await VendeurAPI.getSheduledAssignmentsBuses();
             setBuses(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des affectations du jours !");
         }
     };

     const fetchSetting = async () => {
         try{
             const data =  await CustomAPI.findSetting();
             setSetting(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des paramettres !");
         }
     };


     const getTrajets = async () => {
         try{
             const data =  await VendeurAPI.getTrajets();
             setTrajets(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des trajets !");
         }
     };


     const dailySales = async () => {
         try{
             const data =  await VendeurAPI.sheduledSales();
             setDailyTickets(data.buses);
             setTotal(data.total);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des ventes du jour !");
         }
     };

     const fetchDailyTransiteOrDirectBuses = async () => {
         try{
             const data =  await VendeurAPI.getDirectOrTransiteAssignmentsBuses();
             setDailybuses(data);
             setLoading(false);
         }catch(error){
             toast.error("Erreur lors du chargement des bus du jours !");
         }
     };



     useEffect(() => {
         fetchDailyAssignments();
         fetchDailyTransiteOrDirectBuses();
         getTrajets();
         dailySales();
         fetchSetting();
     },[]);

     const handleChex = () => {
         setChecked(!checked);
         if (!checked === true) {
             setTicket({...ticket, bonus : 1});
         }else{
             setTicket({...ticket, bonus : 0});
         }

     };

     const  handleCloseBus = async () => {

         try{

             setShowLoader(true);
             setTimeout(() => setShowLoader(false), 1000);

             await VendeurAPI.closedBus(assign);
             toast.success("Le bus a été cloturé avec succès");
             window.location.reload();
         }catch({response}){
             setTimeout(() => setShowLoader(false), 1000);
             const {message} = response.data;
             toast.error(message);
         }
     };


     const handleChange = ({currentTarget}) => {
         const {name, value} = currentTarget;
         setTicket({...ticket, [name] : value});
     };

     useEffect(() => {
         //console.log(ticket);
     },[ticket]);

     const handleSubmit = async (event) => {
         event.preventDefault();

         try{

             setShowLoader(true);
             setTimeout(() => setShowLoader(false), 1000);

             await VendeurAPI.storeTickets(ticket);
             toast.success("Billet ajouté avec succès");
             window.location.reload();
         }catch({response}){
             setTimeout(() => setShowLoader(false), 1000);
             const {violations} = response.data;

             if(violations){
                 const apiErrors = {};
                 Object.keys(violations).forEach(key => {
                     apiErrors[key] = violations[key]
                 });

                 setErrors(apiErrors);
                 toast.error("Une erreur est servenue dans le formulaire d'ajout de billet !");
             }
         }
     };

     const [currentPage, setCurrentPage] = useState(1);
     const [search, setSearch] = useState("");

     //Gestion du changement de page
     const handleChangeCurrentPage = page => {
         setCurrentPage(page);
     };

     //Gestion de la recherche
     const handleSearch = ({currentTarget}) => {
         setSearch(currentTarget.value);
         setCurrentPage(1);
     };

     const itemsPerPage = 9999;

     //Filtrage des buses en fonction de la recherche


     const filteredTickets = dailyTickets.filter(

         b =>
             b.customer.toLowerCase().includes(search.toLowerCase())
             //b.to.toLowerCase().includes(search.toLowerCase()) ||
             //b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
             //b.from.toLowerCase().includes(search.toLowerCase())
     );


     //Pagination des données
     const paginatedTickets = Pagination.getData(
         filteredTickets,
         currentPage,
         itemsPerPage
     );

     function calculSubTotal(data) {
         let tCDF = 0;
         data.map((ticket) => tCDF += ticket.amount);
         return tCDF
     }

     let counter = 1;
     const [busId, setBusId] = useState("");
     const [fretItem, setfretItem] = useState({});

     const handleChangeForBus = ({currentTarget}) => {
         const {name, value} = currentTarget;
         setBusId(value);
         setfretItem({...fretItem, busItem: busId});
     };

     const  handleAddToBus = async (customerId, leBus = busId) => {

         try{

             setShowLoader(true);
             setTimeout(() => setShowLoader(false), 1000);

             await VendeurAPI.addCustomerToBus(customerId, leBus);
             toast.success("Le bus a été ajouté avec succès");
             window.location.reload();
         }catch({response}){
             setTimeout(() => setShowLoader(false), 1000);
             const {message} = response.data;
             toast.error(message);
         }
     };


     return <div className="container">
         <div className="row">
             <div className="col-md-6">
                 <div className="card">
                     <form onSubmit={handleSubmit}>
                         <div className="card-body">
                             <h3 className="card-title text-success">Réserver un ticket</h3>

                             {!loading && <Select name="destination" label="Destination" value={ticket.destination}
                                                  error={errors.destination} onChange={handleChange}>
                                 <option value="">Choisir</option>
                                 {trajs && trajs.map(traj =>
                                     <option key={traj.id} value={traj.id}>{traj.to}</option>)
                                 }
                             </Select>}

                             <div className="row">
                                 <div className="col-md-8">
                                     <Field
                                         name="customer"
                                         error={errors.customer}
                                         value={ticket.customer}
                                         onChange={handleChange}
                                         label="Nom du client"
                                         placeholder="Client..."
                                         autoComplete="off"
                                     />
                                 </div>
                                 <div className="col-md-4">
                                     <label htmlFor="bonus" className="text-success bold">Bonus KKS ?</label>
                                     <CheckBoxField
                                         name="bonus"
                                         id="bonus"
                                         value={ticket.bonus}
                                         label="Cocher ici !"
                                         onChange={handleChex}
                                         //checked={checked ? "checked" : ""}
                                     />
                                 </div>
                             </div>

                             <div className="row">
                                 <div className="col-md-8">
                                     <Field
                                         name="phone"
                                         error={errors.phone}
                                         value={ticket.phone}
                                         onChange={handleChange}
                                         label="Téléphone du client"
                                         placeholder="+243..."
                                         autoComplete="off"
                                     />
                                 </div>
                             </div>


                             <MyButton
                                 text="Enregistrer"
                                 onSubmit={handleSubmit}
                                 loading={showLoader}
                                 disabled={showLoader}
                             />
                             {/*<Link to="#" className="btn btn-danger mx-2">Annuler</Link>*/}
                         </div>
                     </form>
                 </div>
             </div>
             <div className="col-md-6">
                 <div className="card">
                     <div className="card-body">
                         <h3 className="card-title text-primary">Destinations et prix</h3>
                         <ol className="list-group list-group-numbered">
                             {
                                 trajs ? (trajs.map((traj) => (
                                     <li key={traj.id}
                                         className="list-group-item d-flex justify-content-between align-items-start">
                                         <div className="ms-2 me-auto">
                                             <div className="fw-bold">{traj.to}</div>
                                         </div>
                                         <span
                                             className="badge bg-success bg-opacity-75 rounded-1">{traj.price} CDF</span>
                                     </li>
                                 ))) : (<></>)
                             }
                         </ol>
                     </div>
                 </div>
             </div>
         </div>
         <div className="row">
             <div className="col-md-12">
                 <div className="card">
                     <div className="card-body">
                         <h2 className="card-title">
                             Tickets de Réservation : <b className="text-primary">{paginatedTickets ? paginatedTickets.length : 0} </b>
                             Total : <b className="text-primary">{calculSubTotal(paginatedTickets)}</b>
                         </h2>

                         <div className="row mb-3">
                             <div className="col-md-6">
                                 <div className="form-group mt-3">
                                     <label htmlFor="ByAll" className="text-dark">
                                         Recherche par Bus
                                     </label>
                                     <input type="text"
                                            onChange={handleSearch}
                                            value={search}
                                            name="ByAll"
                                            id="ByAll"
                                            className="form-control"
                                            placeholder="Rechercher ..."
                                     />
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div className="card card-body">
                     <table className="table table-hover table-striped">
                         <thead>
                         <tr>
                             <th scope="col">Client</th>
                             <th scope="col">Destination</th>
                             <th scope="col">Montant</th>
                             <th scope="col">Date</th>
                             <th scope="col">Vendeur</th>
                             <th scope="col">Bonus</th>
                             <th scope="col">Option</th>
                         </tr>
                         </thead>
                         <tbody>
                         {paginatedTickets.map((ticket) => (
                             <tr key={ticket.id}>
                                 <td><b>{ticket.customer}</b> <br/> <small>{ticket.bus_number}</small></td>
                                 <td>{ticket.destination}</td>
                                 <td>{ticket.amount} CDF</td>
                                 <td><small className="text-danger">Le {Moment(ticket.created_at).format("DD/MM/YYYY")}</small></td>
                                 <td><small className="text-primary">{ticket.saler}</small></td>
                                 <td>{ticket.bonus === 1 ? (<b className="badge text-bg-success">Oui</b>)
                                     :(<b className="badge text-bg-dark">Non</b>)}
                                 </td>
                                 <td>
                                     <td>
                                         <div className="input-group">
                                             <select
                                                 //value={busId}
                                                 name="busId"
                                                 onChange={handleChangeForBus}
                                                 className="form-select" id="busId"
                                                 aria-label="Selectionner le bus">
                                                 <option value="">Choisir...</option>
                                                 {dailybuses && dailybuses.map((bus) => (
                                                     <option key={bus.id} value={bus.id}>
                                                         {bus.bus_numberplate} - Vers : {bus.to}</option>
                                                 ))
                                                 }

                                             </select>

                                             <button
                                                 onClick={() => {
                                                     if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                         handleAddToBus(ticket.id)
                                                     }
                                                 }}
                                                 disabled={(busId === "") ? "disabled" : ""}
                                                 className="btn btn-sm btn-success">
                                                 Ajouter
                                             </button>

                                             <button className="btn btn-primary">
                                                 <PrintComponent plaque="Reservation" hour="No definie" setting={setting} ticket={ticket}/>
                                             </button>
                                         </div>
                                     </td>
                                 </td>

                             </tr>
                         ))}
                         </tbody>
                     </table>
                 </div>
             </div>
         </div>
     </div>;
 };

 export default NewVenteReservation;
