import axios from "axios";
import {PVS_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    const cachedPVs = await Cache.get("AdminPVs");
    //if(cachedPVs) return cachedPVs;
    return axios
        .get(PVS_API)
        .then(response => {
            const pvs = response.data.pvs;
            Cache.set("AdminPVs", pvs);
            return pvs;
        });
}


async function find(id){

    const cachedPVs = await Cache.get("AdminPVs." + id);
    //if(cachedPVs) return cachedPVs;
    return axios.get(PVS_API + "/" + id)
        .then(response => {
            const pv = response.data.pv;
            Cache.set("AdminPVs." + id, pv);
            return pv;
        });
}


function deletePv(id) {

    return axios
        .delete(PVS_API + "/" + id)
        .then(async response => {
            const cachedPVs = await Cache.get("pvs");

            if(cachedPVs){
                Cache.set("AdminPVs", cachedPVs.filter(i => i.id != id));
            }

            return response;
        });
}

function updatePv(id, pv){

    return axios.post(PVS_API + "/store", {...pv, id: id})
        .then(async response => {

            // const cachedPVs = await Cache.get("AdminPVs");
            //
            // const cachedPV = await Cache.get("AdminPVs." + id);
            //
            // if(cachedPV){
            //     Cache.set("AdminPVs." + id, response.data.pv);
            // }
            //
            // if(cachedPVs){
            //     const index = cachedPVs.findIndex(b => b.id === +id);
            //     cachedPVs[index] = response.data.pv;
            // }

            return response;
        });
}

function create(pv){

    return axios.post(PVS_API + "/store", pv)
        .then(async response => {
            const cachedPVs = await Cache.get("AdminPVs");

            // if(cachedPVs){
            //     Cache.set("AdminPVs", [...cachedPVs, response.data.pv]);
            // }

            return response;
        });
}

export default {
    findAll,
    find,
    create,
    updatePv,
    delete : deletePv,
};