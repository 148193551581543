import React,{useState} from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AuthAPI from "../../../Services/auth/authAPI";
import Manager_header from "../headers/Manager_header";
import ManagerSidebar from "../sidebars/ManagerSidebar";

export const ManagerLayout = ({ allowedRoles }) => {
    const location = useLocation();


    //console.log("jwt role is "+roles+" but allowed role is "+allowedRoles);
    return (AuthAPI.getUserRoles() ===
        allowedRoles[0]
    ) ? (
        <>
            <Manager_header />
            <ManagerSidebar/>
            <main id="main" className="main">
                <Outlet />
            </main>

        </>
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default ManagerLayout;
