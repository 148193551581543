import React, { useRef,useState,useEffect } from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./componentToPrint";
import {Link} from "react-router-dom";
import {useUser} from "../../../Services/userProvider";
import {toast} from "react-toastify";
import CustomAPI from "../../../apis/customerApi/VendeurAPI";
import ComponentFretToPrint from "./componentFretToPrint";

export default function PrintComponent({ data, critere, type}) {
    let componentRef = useRef();
    const user = useUser();
    const [param, setSetting] = useState();

    const fetchSetting = async () => {
        try{
            const data =  await CustomAPI.findSetting();
            setSetting(data);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <Link className="btn btn-sm btn-success mt-3" to="#">
                        Imprimer <i className="bi bi-printer"></i>
                    </Link>}
                    content={() => componentRef}
                />

                {/* component to be printed */}
                {type === "fret" ? (<div style={{ display: "none" }}>
                    <ComponentFretToPrint param={param} critere={critere} data={data} ref={(el) => (componentRef = el)} />
                </div>) :
                    (<div style={{ display: "none" }}>
                    <ComponentToPrint param={param} critere={critere} data={data} ref={(el) => (componentRef = el)} />
                </div>)}
            </div>
        </>
    );
}