import React, { useEffect, useState } from 'react';
import {Link, useParams} from "react-router-dom";
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import Pagination from "../../assets/Pagination";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";
import TableLoader from "../../loaders/TableLoader";

const DailyAdminBusSpents = () => {

    let {city} = useParams();
    const [dailyBusSpents, setDailyBusSpents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");

    const getAllBusSpents = async () => {
        try{
            const data =  await BielletterieAPI.dailyBusSpents();
            setDailyBusSpents(data.spents);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    useEffect(() => {
        getAllBusSpents();
    },[]);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 99999;


    //Filtrage des buses en fonction de la recherche

    const filteredBusSpents = dailyBusSpents.filter(
        b =>
            b.assignment.bus_number.toLowerCase().includes(search.toLowerCase()) ||
            b.assignment.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
            b.assignment.p_name.toLowerCase().includes(search.toLowerCase()) ||
            b.assignment.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.assignment.to.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedBusSpents = Pagination.getData(
        filteredBusSpents,
        currentPage,
        itemsPerPage
    );

    const handleDelete = async id => {
        try{
            await BielletterieAPI.deleteBusSpent(id);
            toast.success("La depense a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression de la facture !");
        }
    };


    const tooltip = (data) => (
        <Tooltip className="" id="tooltip">
            <ul className="list-group">
                {data && data.map((sp, index) => (<li key={index}>{sp.title} - {sp.amount} {sp.currency}</li>))}
            </ul>
        </Tooltip>
    );


    let counter = 1;

    function calculTotal(data) {
        let tUSD = 0;
        let tCDF = 0;
        data.map((spent) => tCDF += spent.totalCDF);
        data.map((spent) => tUSD += spent.totalUSD);
        return {tCDF, tUSD}
    }



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="card-title">
                                        Mes bus - Total : <b className="text-primary">{paginatedBusSpents ? paginatedBusSpents.length : 0} </b>
                                    </h2>
                                </div>
                                <div className="col-md-6">
                                    <h2 className="card-title">Total Bus depense du jour</h2>
                                    <h3><b>{calculTotal(paginatedBusSpents).tUSD} USD | {calculTotal(paginatedBusSpents).tCDF} CDF</b></h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <Link to="/admin/rapports/dailyBusSpents" className={!city ? "btn btn-warning" : "btn btn-outline-warning"}>Toutes les villes</Link>
                                    <Link to="/admin/rapports/dailyBusSpentsByCity/Lubumbashi" className={city==='Lubumbashi' ? "btn btn-primary" : "btn btn-outline-primary"}>Rapport Lubumbashi</Link>
                                    <Link to="/admin/rapports/dailyBusSpentsByCity/Likasi" className={city==='Likasi' ? "btn btn-success" : "btn btn-outline-success"}>Rapport Likasi</Link>
                                    <Link to="/admin/rapports/dailyBusSpentsByCity/Fungurume" className={city==='Fungurume' ? "btn btn-danger" : "btn btn-outline-danger"}>Rapport Fungurume</Link>
                                    <Link to="/admin/rapports/dailyBusSpentsByCity/Kolwezi" className={city==='Kolwezi' ? "btn btn-info" : "btn btn-outline-info"}>Rapport Kolwezi</Link>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Rechercher par Bus
                                        </label>
                                        <input type="text" onChange={handleSearch} value={search}
                                               name="search" id="search" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Depart</th>
                                    <th scope="col">Total USD</th>
                                    <th scope="col">Total CDF</th>
                                    <th scope="col">Vendeur</th>
                                    <th scope="col">Agence</th>
                                    <th scope="col">Options</th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {paginatedBusSpents.map((spent) => (
                                        <tr key={spent.id}>
                                            <th scope="row">{counter++}</th>
                                            <td><b>{spent.assignment.bus_numberplate}</b></td>
                                            <td>{spent.assignment.to}</td>
                                            <td>{spent.assignment.bus_hour}</td>
                                            <td>{spent.totalUSD} $</td>
                                            <td>{spent.totalCDF} FC</td>
                                            <td>{spent.name}</td>
                                            <td>{spent.pos_name}</td>
                                            <td>
                                                {/*<div className="btn-group">*/}
                                                    {/*<ButtonToolbar>*/}
                                                        {/*<OverlayTrigger placement="top"*/}
                                                                        {/*overlay={tooltip(spent.bus_spent_items)}>*/}
                                                            {/*<Button className="badge btn btn-primary">*/}
                                                                {/*<i className="bi bi-eye"></i> Voir les depenses*/}
                                                            {/*</Button>*/}
                                                        {/*</OverlayTrigger>*/}
                                                    {/*</ButtonToolbar>*/}
                                                {/*</div>*/}

                                                <ul className="list-group mx-2">
                                                    {spent.bus_spent_items && spent.bus_spent_items.map((sp, index) =>
                                                        (<li key={index}>{sp.title} - {sp.amount} {sp.currency}</li>))
                                                    }
                                                </ul>

                                                {spent.bus_spent_items.length > 0 && (<button
                                                    onClick={() => {
                                                        if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                            handleDelete(spent.id)
                                                        }
                                                    }}
                                                    className="btn btn-sm btn-danger">
                                                    Sup ces depenses
                                                </button>)}
                                            </td>
                                        </tr>
                                    ))
                                }

                                </tbody>}
                            </table>

                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredBusSpents.length &&
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredBusSpents.length}
                                onPageChange={handleChangeCurrentPage}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyAdminBusSpents;
